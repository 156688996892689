import axios from 'axios'
//import {AES,enc} from 'crypto-js';
const urlAgendamentos = '/PED/rest/controle-agendamento/agendamentos?'
import { useLoginStore } from './stores/LoginStore'

const formato = 'JSON'
let emptyList = []

class UtilsApplication {
	//static token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIiLCJwYXNzd29yZCI6InpZaUI3TTV4Uk5Jb1pYTzRBVzBCd2ZBVkRXd3RFUEJ2T1Q1ekgwWWlXTm89IiwiaXNzIjoiYnIuZ292LmNlbWFkZW4iLCJleHAiOjE2NjI5OTE2NzAsImlhdCI6MTY2MjM4Njg3MCwianRpIjoiYTJlNDI5ZGItYzFkYS00ZDMwLWI4ZTAtZWE1NTZkOWUzNWU2IiwidXNlcm5hbWUiOiIxUnJyc2FvaEVaQjhpYkJJMFNjeHBEaktKNDFxUlVzVnpDRE1vcyt6bXlFPSJ9.KHqXnznmEF42PAr7GuKLnfWLItI2M9LYp3OrPWMdQ54';

	static client = axios.create({
		baseURL: process.env.VUE_APP_SWS_BASE_URL,
	})

	static async execute(options) {
		const loginStore = useLoginStore()

		options.headers = {
			//'token':  localStorage.getItem('token') == (null || undefined) ? null : AES.decrypt(localStorage.getItem('token'), 'MYKEY4DEMO').toString(enc.Utf8)
			token:
				loginStore.token == ('' || null || undefined) ? null : loginStore.token,
		}

		let response = await this.client(options).catch((error) => {
			console.log('Houve um erro ao chamar o ws!', error)
			return error.response
		})

		if (response.status == 200) {
			return response.data
		} else {
			return emptyList
		}
	}

	static async agendamentos(statusreq) {
		const options = {
			url: urlAgendamentos,
			method: 'GET',
			params: {
				statusreq: statusreq != '' ? statusreq : undefined,
				formato: formato,
			},
		}

		return UtilsApplication.execute(options)
	}
}

export default UtilsApplication
