// JavaScript
export function startLoadingAnimation() {
	//const animationFrames = ["-", "\\", "|", "/"];
	const animationFrames = ['⠋', '⠙', '⠹', '⠸', '⠼', '⠴', '⠦', '⠧', '⠇', '⠏']
	let frame = 0
	const loadingAnimationElement = document.getElementById('loadingAnimation')
	// Função que altera o frame da animação
	function updateAnimation() {
		loadingAnimationElement.textContent = `Carregando... ${animationFrames[frame]}`
		frame = (frame + 1) % animationFrames.length
	}

	// Iniciar a animação a cada 150ms
	const animationInterval = setInterval(updateAnimation, 150)

	// Retornar o intervalo da animação para que possa ser parado posteriormente
	return animationInterval
}

export function stopLoadingAnimation(animationInterval) {
	clearInterval(animationInterval)
	const loadingAnimationElement = document.getElementById('loadingAnimation')
	loadingAnimationElement.textContent = '' // Limpar a animação
}
