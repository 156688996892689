<template>
	<!-- Main Content -->
	<div class="container">
		<h3 class="p-3 text-center">Consulta de Dados Recentes</h3>
		<!-- Este div serve para consulta de dados acumulados -->
		<form id="formMatrix">
			<div id="divLocalizacao" class="form-group">
				<div class="row">
					<div id="divEstado" class="col-3">
						<label for="selectUF">UF (*)</label>
						<select
							class="form-select"
							id="selectUF"
							v-model="uf"
							v-on:change="carregaCbMunicipios"
							v-on:click.once="carregaCbTpEstacoesSensores"
						>
							<option value="">Selecione o estado</option>
							<option v-for="(uf, index) in ufs" :value="uf" :key="index">
								{{ uf }}
							</option>
						</select>
					</div>
					<div id="divCity" class="col-4">
						<label for="selectCity">Município</label>
						<select
							class="form-select"
							id="selectCity"
							v-model="municipio"
							v-on:change="carregaCbEstacoes"
						>
							<option value="">Selecione o município</option>
							<option
								v-for="(municipio, index) in municipios"
								:value="municipio.codibge"
								:key="index"
							>
								{{ municipio.cidade }}
							</option>
						</select>
					</div>
				</div>
			</div>

			<div class="gap-40"></div>

			<div id="divEstacao" class="form-group">
				<div class="row">
					<div id="divTpEstacao" class="col-3">
						<label for="selectTpEstacao">Tipo de Estação</label>
						<select
							class="form-select"
							id="selectTpEstacao"
							v-model="tpEstacao"
							v-on:click.once="carregaCbTpEstacoes"
							v-on:change="filtraCbEstacoesPorTipo"
						>
							<option value="">Selecione o tipo de estação</option>
							<option
								v-for="(tpEstacao, index) in tpEstacoes"
								:value="tpEstacao.tipoestacao"
								:key="index"
							>
								{{ tpEstacao.tipoestacaodescricao }}
							</option>
						</select>
					</div>

					<div id="divStation" class="col-5">
						<label for="selectStation">Estação</label>
						<select class="form-select" id="selectStation" v-model="estacao">
							<option value="">Selecione a estação</option>
							<option
								v-for="(estacao, index) in estacoes"
								:value="estacao"
								:key="index"
							>
								{{ estacao.nome === '' ? ' - ' : estacao.nome + ' - ' }}
								{{ estacao.codestacao }}
							</option>
						</select>
					</div>
				</div>
			</div>

			<div class="gap-40"></div>

			<div id="divSensor" class="form-group">
				<div class="row">
					<div id="divSensor" class="col-4">
						<label for="selectTpSensor">Tipo de Sensor</label>
						<select class="form-select" id="selectTpSensor" v-model="sensor">
							<option value="">Selecione o tipo de sensor</option>
							<option
								v-for="(sensor, index) in sensores"
								:value="sensor.sensor"
								:key="index"
							>
								{{ sensor.sensordescricao }}
							</option>
						</select>
					</div>
				</div>
			</div>

			<div class="gap-40"></div>

			<div id="divButton" class="form-group">
				<div class="row">
					<div class="col-1">
						<button
							id="btnBuscarDadosRecentes"
							@click.prevent="buscarDadosRecentes"
							type="submit"
							class="btn btn-sm btn-info"
						>
							Consultar
						</button>
					</div>
					<div class="col-2">
						<pre id="loadingAnimation"></pre>
						<!--<div id="divCarregamento" v-if="isLoading">carregando...</div>
						<loading-animation :isLoading="isLoading" />
						-->
					</div>
				</div>
			</div>

			<div class="gap-10"></div>

			<div v-if="error" id="divMsgError">
				<p>{{ error }}</p>
			</div>

			<div class="gap-10"></div>
		</form>
	</div>

	<div class="gap-40"></div>

	<table
		class="table table-hover table-responsive-sm"
		style="width: 90%"
		id="tb_dadosrecentes"
	></table>
	<div class="gap-20"></div>

	<div id="divTextBox" v-if="isTableOn" class="form-group row">
		<div class="row">
			<div class="col-8">
				<div class="alert alert-info" role="alert" align="center">
					(*) Para obter maiores informações sobre o sensor favor consultar a
					opção <strong>Lista de Sensores</strong>
					que consta do menu superior.
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { ref } from 'vue'
	//import {  onBeforeUnmount } from 'vue';
	import 'bootstrap/dist/css/bootstrap.min.css'
	import 'jquery/dist/jquery.min.js'
	
	//Datatable modules
	import 'datatables.net-buttons-dt/css/buttons.dataTables.min.css';
	import 'datatables.net-buttons/js/dataTables.buttons.min.js';
	import 'datatables.net-buttons/js/buttons.html5.min.js';
	import "datatables.net";
	import "datatables.net-dt";
	import "datatables.net-dt/css/jquery.dataTables.css";

	import $ from 'jquery'
	import SwsApplication from '@/sws.application.js'

	import {
		startLoadingAnimation,
		stopLoadingAnimation,
	} from '@/load.animation.js'

	export default {
		name: 'ConsultaDadosRecentes',
		components: {},
		setup() {
			let uf = ref('')
			let ufs = ref([
				'AC',
				'AL',
				'AM',
				'AP',
				'BA',
				'CE',
				'DF',
				'ES',
				'GO',
				'MA',
				'MG',
				'MS',
				'MT',
				'PA',
				'PB',
				'PE',
				'PI',
				'PR',
				'RJ',
				'RN',
				'RO',
				'RR',
				'RS',
				'SC',
				'SE',
				'SP',
				'TO',
			])
			let municipio = ref('')
			let municipios = ref([])
			let estacao = ref('')
			let estacoes = ref([])
			let todasEstacoesMunicipio = ref([])
			let tpEstacoes = ref([])
			let tpEstacao = ref('')
			let sensores = ref([])
			const isTableOn = ref(false)
			let sensor = ref('')
			const error = ref('')

			function carregaCbMunicipios() {
				if (this.uf) {
					//Reseta msg de erro
					this.error = ref('')
					//Reseta o combo de estações
					this.estacoes = ref([])
					//Reseta o valor inicial do combo de Estação
					this.estacao = ref('')
					//Reseta o valor inicial do combo de Tipo de Estação
					this.tpEstacao = ref('')
					// Carrega combo de municípios da UF selecionada
					SwsApplication.cidades(this.uf).then(
						(data) => (this.municipios = data)
					)
				} else {
					//Reseta o valor inicial do combo de Município
					this.municipio = ref('')
					//Reseta o combo de municípios
					this.municipios = ref([])
					//Reseta o valor inicial do combo de Estação
					this.estacao = ref('')
					//Reseta o combo de estações
					this.estacoes = ref([])
					//Reseta o valor inicial do combo de Tipo de Estação
					this.tpEstacao = ref('')
				}
			}

			function carregaCbEstacoes() {
				if (this.municipio) {
					SwsApplication.estacoes(this.municipio).then((data) => {
						this.estacoes = data
						this.todasEstacoesMunicipio = this.estacoes
					})
				} else {
					//Reseta o valor inicial do combo de Estação
					this.estacao = ref('')
					//Reseta o combo de estações
					this.estacoes = ref([])
					//Reseta o valor inicial do combo de Tipo de Estação
					this.tpEstacao = ref('')
				}
			}

			async function carregaCbTpEstacoesSensores() {
				//let resultado = await SwsApplication.pcdsTipoEstacaoSensores().then(data => this.tpEstacoes = data);

				try {
					const resultado = await SwsApplication.pcdsTipoEstacaoSensores().then(
						(data) => (this.tpEstacoes = data)
					)
					// eslint-disable-next-line no-undef
					let sensoresUnfiltered = []
					if (resultado && typeof resultado === 'object') {
						resultado.forEach(function (tpEst) {
							sensoresUnfiltered = sensoresUnfiltered.concat(tpEst.sensor)
						})

						for (let sensor of sensoresUnfiltered) {
							const sensorExistente = this.sensores.find(
								(item) => item.sensor === sensor.sensor
							)
							if (!sensorExistente) {
								this.sensores.push(sensor)
							}
						}
					}
				} catch (error) {
					console.error(error)
				}
			}

			function filtraCbEstacoesPorTipo() {
				this.estacoes = this.todasEstacoesMunicipio

				if (this.tpEstacao != 0) {
					//loop em this.estacoes para filtrar por tipo de estacao
					let estacoesFiltered = this.estacoes.filter(
						(estacao) => estacao.id_tipoestacao == this.tpEstacao
					)
					this.estacoes = estacoesFiltered
				}
			}

			async function buscarDadosRecentes() {
				if ($.fn.DataTable.isDataTable('#tb_dadosrecentes')) {
					$('#tb_dadosrecentes').DataTable().clear()
					$('#tb_dadosrecentes').DataTable().destroy()
					var tableId = '#tb_dadosrecentes'
					//2nd empty html
					$(tableId + ' tbody').empty()
					$(tableId + ' thead').empty()
					$('#tb_dadosrecentes').empty()
				}

				isTableOn.value = false

				let params = {}
				const rede = 11
				params.rede = rede

				if (this.uf) {
					params.uf = this.uf
				} else {
					this.error = 'Por favor selecione uma UF!'
					return
				}
				if (this.municipio) {
					params.codibge = this.municipio
				}
				if (this.tpEstacao) {
					params.tipoestacao = this.tpEstacao
				}
				if (this.estacao.codestacao) {
					params.codestacao = this.estacao.codestacao
				}
				if (this.sensor) {
					params.sensor = this.sensor
				}

				const animationInterval = startLoadingAnimation()

				$(async function () {
					let data = await SwsApplication.pcdsDadosRecentes(
						params.rede,
						params.uf,
						params.codibge,
						params.tipoestacao,
						params.codestacao,
						params.sensor
					)

					if (data) {
						isTableOn.value = true
						stopLoadingAnimation(animationInterval)
					}

					$('#tb_dadosrecentes').DataTable({
						data: data,
						language: {
							emptyTable:
								'Nenhum resultado foi encontrado para essa requisição.',
						},
						destroy: true,
						paging: true,
						searching: true,
						processing: true,
						select: true,
						dom: 'Bfrtip',
						buttons: ['copy', 'csv', 'excel'],
						columnDefs: [
							{
								targets: '_all',
								defaultContent: '-',
								className: 'dt-body-left',
							},
						],
						columns: [
							{
								data: 'datahora',
								title: 'datahora',
							},
							{
								data: 'uf',
								title: 'uf',
							},
							{
								data: 'cidade',
								title: 'cidade',
							},
							{
								data: 'codestacao',
								title: 'codestacao',
							},
							{
								data: 'nome',
								title: 'nome',
							},
							{
								data: 'latitude',
								title: 'latitude',
							},
							{
								data: 'longitude',
								title: 'longitude',
							},
							{
								data: 'offset',
								title: 'offset',
							},
							{
								data: 'id_sensor',
								title: 'sensor (*)',
							},
							{
								data: 'valor',
								title: 'valor',
							},
							{
								data: 'qualificacao',
								title: 'qualificacao',
							},
						],
					})
				})
			}

			return {
				uf,
				ufs,
				municipio,
				municipios,
				estacao,
				estacoes,
				todasEstacoesMunicipio,
				tpEstacao,
				tpEstacoes,
				sensor,
				isTableOn,
				sensores,
				error,
				carregaCbMunicipios,
				carregaCbEstacoes,
				carregaCbTpEstacoesSensores,
				filtraCbEstacoesPorTipo,
				buscarDadosRecentes,
			}
		},
		mounted() {
			console.log('Sucesso')
		},
		beforeUnmount() {
			console.log('unmount')
			if ($.fn.DataTable.isDataTable('#tb_dadosrecentes')) {
				if ($('#tb_dadosrecentes').DataTable() != null) {
					var tableId = '#tb_dadosrecentes'

					$('#tb_dadosrecentes').DataTable().clear()
					$('#tb_dadosrecentes').DataTable().destroy()
					//window.removeEventListener('resize', this.someMethod)

					//2nd empty html
					$(tableId + ' tbody').empty()
					$(tableId + ' thead').empty()
					$('#tb_dadosrecentes').empty()
				}
			}
			this.isTableOn = false
		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	h3 {
		margin: 40px 0 0;
		color: #000000;
	}

	ul {
		list-style-type: none;
		padding: 0;
	}

	li {
		display: inline-block;
		margin: 0 10px;
	}

	a {
		color: #42b983;
	}

	/* Estilo para a tag <pre> */
	pre {
		color: blue;
		/* Define a cor do texto para azul */
	}

	.search-result {
		float: left;
		margin-top: 45px;
		text-align: left;
	}

	.search-result-error {
		float: left;
		margin-top: 45px;
		text-align: center;
	}

	/*Bootstrap: Mudança da cor dos botões*/
	.btn-primary {
		background-color: #000000;
		border-color: #000000;
	}

	/*Bootstrap: Mudança da cor dos botões*/
	.btn-info {
		background-color: #3276b1;
		border-color: #3276b1;
		color: #ffffff;
	}

	/*Evita a geração de scroll horizontal*/
	.row {
		margin-right: 0px;
		margin-left: 0px;
	}

	.row-margin-top {
		margin-top: 10px;
	}

	.divData {
		float: left;
		padding-top: 2em;
		padding-bottom: 2em;
	}

	.divDtAtual {
		float: left;
		padding-right: 2em;
		padding-top: 2em;
		padding-bottom: 2em;
		border-radius: 0.5em;
	}

	.divDtHistorica {
		float: left;
		padding-right: 2em;
		padding-top: 2em;
		padding-bottom: 2em;
		border-radius: 0.5em;
	}

	.divObs {
		float: left;
		padding-right: 2em;
		padding-top: 2em;
		padding-bottom: 2em;
		border-radius: 0.5em;
	}

	.gap-40 {
		width: 100%;
		height: 40px;
	}

	.gap-30 {
		width: 100%;
		height: 30px;
	}

	.gap-20 {
		width: 100%;
		height: 20px;
	}

	.gap-10 {
		width: 100%;
		height: 10px;
	}

	#divMsgError {
		text-align: left;
		color: #f30606;
	}

	.alert-info {
		background-image: -webkit-linear-gradient(top, #ffffff 0, #ffffff 100%);
		background-image: -o-linear-gradient(top, #ffffff 0, #ffffff 100%);
		background-image: -webkit-gradient(
			linear,
			left top,
			left bottom,
			from(#ffffff),
			to(#ffffff)
		);
		background-image: linear-gradient(to bottom, #ffffff 0, #ffffff 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9edf7', endColorstr='#ffb9def0', GradientType=0);
		background-repeat: repeat-x;
		border-color: #ffffff;
	}

	.alert {
		/* text-shadow: 0 1px 0 rgba(255,255,255,.2); */
		-webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25),
			0 1px 2px rgba(0, 0, 0, 0.05);
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25),
			0 1px 2px rgba(0, 0, 0, 0.05);
	}

	.alert-info {
		color: #070707;
		background-color: #ffffff;
		border-color: #ffffff;
	}

	.alert {
		padding: 15px;
		margin-bottom: 20px;
		border: 1px solid transparent;
		border-radius: 4px;
	}
</style>
