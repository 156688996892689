<template>
	<!-- Main Content -->
	<div class="container">
		<h3 class="p-3 text-center">Atualização de Perfil de Usuários</h3>

		<form id="formMatrix">
			<div id="divEmail" class="form-group row">
				<div class="row">
					<label for="email" class="col-sm-2 col-form-label">E-mail (*)</label>
					<div class="col-sm-3">
						<input
							type="text"
							class="form-control"
							id="email"
							v-model="email"
							placeholder="E-mail"
						/>
					</div>
				</div>
			</div>

			<div id="divPerfilUsuario" class="form-group row">
				<div class="row">
					<label for="selectPerfil" class="col-sm-2 col-form-label"
						>Perfil (*)</label
					>
					<div class="col-sm-4">
						<select
							class="form-select col-sm-4"
							id="selectPerfil"
							v-model="perfil"
							v-on:click.once="carregaCbPerfis"
						>
							<option value="">Selecione o Perfil</option>
							<option
								v-for="(perfil, index) in perfis"
								:value="perfil.id"
								:key="index"
							>
								{{ perfil.roleName }}
							</option>
						</select>
					</div>
				</div>
			</div>

			<div id="divButton" class="form-group row">
				<div class="row">
					<div class="col-sm-2">
						<button
							id="buttonAtualizarPerfil"
							@click.prevent="atualizarPerfil"
							type="submit"
							class="btn btn-sm btn-info"
						>
							Atualizar
						</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
	import { ref } from 'vue'
	import SgaaApplication from '@/sgaa.application.js'

	export default {
		name: 'AtualizaPerfilUsuario',
		setup() {
			let email = ref('')
			let perfil = ref('')
			let perfis = ref([])

			function carregaCbPerfis() {
				SgaaApplication.perfis().then((data) => (this.perfis = data))
			}

			function atualizarPerfil() {
				if (!this.email || !this.perfil) return

				SgaaApplication.usuarioPerfil(this.email, this.perfil)
			}

			return {
				email,
				perfil,
				perfis,
				carregaCbPerfis,
				atualizarPerfil,
			}
		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	h3 {
		margin: 40px 0 0;
		color: #000000;
	}

	ul {
		list-style-type: none;
		padding: 0;
	}

	li {
		display: inline-block;
		margin: 0 10px;
	}

	a {
		color: #42b983;
	}

	/*Bootstrap: Mudança da cor dos botões*/
	.btn-primary {
		background-color: #000000;
		border-color: #000000;
	}

	/*Bootstrap: Mudança da cor dos botões*/
	.btn-info {
		background-color: #3276b1;
		border-color: #3276b1;
		color: #ffffff;
	}

	/*Evita a geração de scroll horizontal*/
	.row {
		margin-right: 0px;
		margin-left: 0px;
		margin-top: 15px;
	}

	.divEmail {
		float: left;
		padding-right: 2em;
		padding-top: 2em;
		padding-bottom: 2em;
		border-radius: 0.5em;
	}

	.divPerfilUsuario {
		float: left;
		padding-right: 2em;
		padding-top: 2em;
		padding-bottom: 2em;
		border-radius: 0.5em;
	}

	.row-margin-top {
		margin-top: 15px;
	}
</style>

<style src="@vueform/multiselect/themes/default.css"></style>
