<template>
	<div class="menu-item" @click="isOpen = !isOpen" @mouseleave="isOpen = false">
		<i class="fas fa-user-circle custom-arrow-icon" aria-hidden="true"></i>

		<transition name="fade" apear>
			<div class="sub-menu" v-if="isOpen">
				<div class="menu-item" id="alterarSenha">
					<router-link
						to="/acesso/alterarSenhaUsuario"
						class="nav-item nav-link"
						>Alterar Senha</router-link
					>
				</div>
					
				 <div class="menu-item" id="token">
                                        <router-link to="/acesso/pedToken" class="nav-item nav-link">Obter Token do Usuário</router-link>
                                </div>				

				<div class="menu-item" id="sair">
					<a
						href="#"
						@click="loginStore.logout"
						class="nav-item nav-link"
						id="notificacao"
						><span>Sair</span>
					</a>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
	// Importe os estilos CSS do Font Awesome
	import '@fortawesome/fontawesome-free/css/all.css'
	import { useLoginStore } from '../stores/LoginStore.js'

	export default {
		name: 'services',
		props: ['title'],
		data() {
			return {
				isOpen: false,
			}
		},
		setup() {
			const loginStore = useLoginStore()
			return {
				loginStore,
			}
		},
	}
</script>

<style>
	nav .menu-item svg {
		width: 10px;
		margin-left: 10px;
	}
	nav .menu-item .sub-menu {
		position: absolute;
		background-color: #3276b1;
		top: calc(100% + 5px);
		left: 50%;
		transform: translateX(-50%);
		width: max-content;
		border-radius: 0px 0px 16px 16px;
	}
	.fade-enter-active,
	.fade-leave-active {
		transition: all 0.2s ease-out;
	}
	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}
	.sub-menu {
		cursor: pointer;
	}
</style>
