import router from './router/index'
import axios from 'axios'
//import { AES, enc } from 'crypto-js';
import { useLoginStore } from './stores/LoginStore'

const urlCidades = '/PED/rest/pcds-cadastro/cidades?'
const urlRedeEstacoes = '/PED/rest/pcds-cadastro/dados-cadastrais?'
const urlEstacoes = '/PED/rest/pcds-cadastro/estacoes?'
const urlAcumuladosHistoricos = '/PED/rest/pcds-acum/acumulados-historicos?'
const urlAcumuladosRecentes = '/PED/rest/pcds-acum/acumulados-recentes?'
const urlAgendamentos = '/PED/rest/controle-agendamento/agendamentos?'
const urlPcdsDadosRecentes = '/PED/rest/pcds/pcds-dados-recentes?'
const urlPcdsTipoEstacaoSensores = '/PED/rest/pcds-tipo-estacao/sensores?'
const urlAgendarConsultaDadosHistoricos =
	'/PED/rest/controle-agendamento/pcds-dados-historicos?'

const formato = 'JSON'

class SwsApplication {
	//static token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIiLCJwYXNzd29yZCI6InpZaUI3TTV4Uk5Jb1pYTzRBVzBCd2ZBVkRXd3RFUEJ2T1Q1ekgwWWlXTm89IiwiaXNzIjoiYnIuZ292LmNlbWFkZW4iLCJleHAiOjE2NzE0Njg4MTYsImlhdCI6MTY3MTQ1NDQxNiwianRpIjoiODJlODM2MzEtZGUxNS00MDMxLWI3ODctNGY1MTlhNWQ2ODc4IiwidXNlcm5hbWUiOiIxUnJyc2FvaEVaQjhpYkJJMFNjeHBEaktKNDFxUlVzVnpDRE1vcyt6bXlFPSJ9.thLZgGs7o-bv1x_N7JMgagUFxu7H8pvq6y1KT9LnvfY';

	static client = axios.create({
		baseURL: process.env.VUE_APP_SWS_BASE_URL,
	})

	static async execute(options) {
		const loginStore = useLoginStore()

		options.headers = {
			//'token':  localStorage.getItem('token') == (null || undefined) ? null : AES.decrypt(localStorage.getItem('token'), 'MYKEY4DEMO').toString(enc.Utf8)
			token:
				loginStore.token == ('' || null || undefined) ? null : loginStore.token,
		}

		console.log('options = ', options)

		let response = await this.client(options).catch((error) => {
			console.log('Houve um erro ao chamar o ws!', error)
			return error.response
		})

		if (response.status == 200 || response.status == 202) {
			if (options.url == urlAgendarConsultaDadosHistoricos) {
				let msgSucesso =
					'Agendamento realizado com sucesso ! Os dados estarão disponíveis assim que prontos e poderão ser consultados no item Status de Agendamento de dados históricos do menu superior.'
				//navigating
				router.push({
					name: 'PaginaSucesso',
					params: {
						msg: msgSucesso,
					},
				})
			} else {
				return response.data
			}
		} else if (response.status == 400 || response.status == 401) {
			//navigating
			router.push({
				name: 'PaginaErro',
				params: {
					msg: response.data.Alerta,
				},
			})
		} else if (response.status == 404) {
			//navigating
			router.push({
				name: 'PaginaErro',
				params: {
					msg: 'O serviço está inacessível! Aguarde alguns minutos e tente novamente.',
				},
			})
		} else {
			//navigating
			router.push({
				name: 'PaginaErro',
				params: {
					msg: 'Houve um erro ao acessar esse serviço! Aguarde alguns minutos e tente novamente.',
				},
			})
		}
	}

	static async cidades(uf) {
		const options = {
			url: urlCidades,
			method: 'GET',
			params: {
				uf: uf,
				formato: formato,
			},
		}
		return SwsApplication.execute(options)
	}

	static async estacoes(codibge) {
		const options = {
			url: urlEstacoes,
			method: 'GET',
			params: {
				codibge: codibge,
				formato: formato,
			},
		}
		return SwsApplication.execute(options)
	}

	static async acumuladosHistoricos(codestacao, codibge, data) {
		const options = {
			url: urlAcumuladosHistoricos,
			method: 'GET',
			params: {
				codestacao: codestacao,
				codibge: codibge,
				data: data,
				formato: formato,
			},
		}
		return SwsApplication.execute(options)
	}

	static async acumuladosRecentes(codestacao, codibge) {
		const options = {
			url: urlAcumuladosRecentes,
			method: 'GET',
			params: {
				codestacao: codestacao,
				codibge: codibge,
				formato: formato,
			},
		}
		return SwsApplication.execute(options)
	}

	static async redeEstacoes(codestacao, codibge, uf, tipoestacao, formato) {
		const options = {
			url: urlRedeEstacoes,
			method: 'GET',
			params: {
				codestacao: codestacao != '' ? codestacao : undefined,
				codibge: codibge != '' ? codibge : undefined,
				uf: uf != '' ? uf : undefined,
				tipoestacao: tipoestacao != '' ? tipoestacao : undefined,
				formato: formato != '' ? formato : 'JSON',
			},
		}
		return SwsApplication.execute(options)
	}

	static async agendamentos(statusreq) {
		const options = {
			url: urlAgendamentos,
			method: 'GET',
			params: {
				statusreq: statusreq != '' ? statusreq : undefined,
				formato: formato,
			},
		}

		return SwsApplication.execute(options)
	}

	static async pcdsDadosRecentes(
		rede,
		uf,
		codibge,
		tipoestacao,
		codestacao,
		sensor,
	) {
		const options = {
			url: urlPcdsDadosRecentes,
			method: 'GET',
			params: {
				rede: rede,
				uf: uf != '' ? uf : undefined,
				codibge: codibge != '' ? codibge : undefined,
				tipoestacao: tipoestacao != '' ? tipoestacao : undefined,
				codestacao: codestacao != '' ? codestacao : undefined,
				sensor: sensor != '' ? sensor : undefined,
				formato: formato,
			},
		}
		return SwsApplication.execute(options)
	}

	static async agendarConsultaDadosHistoricos(
		dtInicio,
		dtFim,
		rede,
		codestacao,
		uf,
		codibge,
		tipoestacao,
		file,
	) {
		const options = {
			url: urlAgendarConsultaDadosHistoricos,
			method: 'GET',
			params: {
				datainicio: dtInicio,
				datafim: dtFim,
				rede: rede,
				codestacao: codestacao != '' ? codestacao : undefined,
				uf: uf != '' ? uf : undefined,
				codibge: codibge != '' ? codibge : undefined,
				tipoestacao: tipoestacao != '' ? tipoestacao : undefined,
				arquivo: file != '' ? file : formato,
				formato: formato,
			},
		}
		return SwsApplication.execute(options)
	}

	static async pcdsTipoEstacaoSensores(tipoestacao) {
		const options = {
			url: urlPcdsTipoEstacaoSensores,
			method: 'GET',
			params: {
				tipoestacao: tipoestacao,
			},
		}

		return SwsApplication.execute(options)
	}
}

export default SwsApplication
