<template>
	<!-- Main Content -->
	<div class="container">
		<h3 class="p-3 text-center">Cadastro de Usuário</h3>
		<!-- Este div serve para cadastro de um usuario -->
		<form id="formMatrix">
			<div id="divEmail" class="form-group row">
				<div class="row">
					<label for="email" class="col-sm-2 col-form-label">E-mail (*)</label>
					<div class="col-sm-3">
						<input
							type="text"
							class="form-control"
							id="email"
							v-model="email"
							v-on:mouseleave="onMouseLeaveEmail"
							placeholder="E-mail"
						/>
					</div>
					<div class="col-sm-3">
						<p
							:style="{ color: paragraphColorEmail, textAlign: 'left' }"
							id="msgEmail"
						></p>
					</div>
				</div>
			</div>

			<div id="divPassword" class="form-group row">
				<div class="row">
					<label for="passwd" class="col-sm-2 col-form-label">Senha (*)</label>
					<div class="col-sm-3">
						<input
							type="password"
							class="form-control"
							id="passwd"
							v-model="passwd"
							v-on:mouseleave="onMouseLeaveSenha"
							placeholder="Senha"
							data-bs-toggle="tooltip"
							data-bs-placement="top"
							data-bs-html="true"
							title="A senha informada deve obedecer às seguintes regras de formação:1 - Conter pelo menos um dígito;2 - Pelo menos um caractere maiúsculo;3 - Pelo menos um caractere minúsculo;4 - Não pode conter espaços em branco;5 - Possuir pelo menos 8 caracteres."
						/>
					</div>
					<div class="col-sm-3">
						<p
							:style="{ color: paragraphColorPasswd, textAlign: 'left' }"
							id="msgSenha"
						></p>
					</div>
				</div>
			</div>

			<div id="divPerfilUsuario" class="form-group row">
				<div class="row">
					<label for="selectPerfil" class="col-sm-2 col-form-label"
						>Perfil (*)</label
					>
					<div class="col-sm-4">
						<select
							class="form-select col-sm-4"
							id="selectPerfil"
							v-model="perfil"
							v-on:click.once="carregaCbPerfis"
						>
							<option value="">Selecione o Perfil</option>
							<option
								v-for="(perfil, index) in perfis"
								:value="perfil.id"
								:key="index"
							>
								{{ perfil.roleName }}
							</option>
						</select>
					</div>
				</div>
			</div>

			<div id="divCaptcha" class="form-group row">
				<div class="row">
					<div class="col-sm-2"></div>
					<div class="col-sm-2">
						<!--
						<div v-if="verified" id="verified">
							<h1>Successfully Verified!</h1>
							<p>token: {{ tokenCaptcha }}</p>
							<p>eKey: {{ eKey }}</p>
						</div>
						-->
						<div v-if="expired" id="expired">
							<h1>Challenge expired!</h1>
						</div>
						<div v-if="error" id="error">
							<h1>Error:</h1>
							<p>{{ error }}</p>
						</div>
						<vue-hcaptcha
							sitekey="98ceb645-6a20-4b30-8d03-b709323fd8d7"
							@verify="onVerify"
							@expired="onExpire"
							@challenge-expired="onChallengeExpire"
							@error="onError"
						></vue-hcaptcha>
					</div>
				</div>
			</div>

			<div id="divButton" class="form-group row">
				<div class="row">
					<div class="col-sm-3">
						<button
							id="buttonCriarUsuario"
							@click.prevent="criarUsuario"
							type="submit"
							class="btn btn-sm btn-success"
							style="float: right"
						>
							Criar conta
						</button>
					</div>
				</div>
			</div>

			<div id="divButtonVoltar" class="form-group row">
				<div class="row">
					<div class="col-sm-3">
						<button
							id="buttonVoltar"
							@click.prevent="$router.back()"
							type="submit"
							class="btn btn-sm btn-info"
							style="float: right"
						>
							Voltar
						</button>
					</div>
				</div>
			</div>

			<div class="gap-20"></div>

			<div v-if="error" id="divMsgError">
				<p>{{ error }}</p>
			</div>

			<div class="gap-30"></div>

			<div id="divTextBox" class="form-group row">
				<div class="row">
					<div class="alert alert-info" role="alert">
						Obs:
						<strong
							>O Cemaden não armazena informações pessoais do usuário</strong
						>, e, portanto, não incorre na LGPD. As informações de cadastro do
						usuário são armazenadas de forma criptografada, não sendo possível a
						sua recuperação.
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
	import { ref, onMounted, watch } from 'vue'
	import SgaaApplication from '@/sgaa.application.js'
	import VueHcaptcha from '@hcaptcha/vue3-hcaptcha'
	import { Tooltip } from 'bootstrap'
	//import { validaSenha } from '@/utils.security.js'
	import { enviarEmailUsuarioCadastro } from '@/mail.usermsg.js'
	import { useRouter } from 'vue-router'

	onMounted(() => {
		new Tooltip(document.body, {
			selector: '[data-bs-toggle="tooltip"]',
		})
	})

	export default {
		name: 'CadastroUsuario',
		components: {
			VueHcaptcha,
		},
		setup() {
			const verified = ref(false)
			const expired = ref(false)
			const senhaOk = ref(false)
			const emailOk = ref(false)
			const tokenCaptcha = ref('')
			const eKey = ref('')
			const error = ref('')
			const paragraphColorEmail = ref('red')
			const paragraphColorPasswd = ref('red')
			const router = new useRouter()

			let email = ref('')
			let passwd = ref('')
			let perfil = ref('')
			let perfis = ref([])
			let token = ref('')

			function carregaCbPerfis() {
				SgaaApplication.perfis().then((data) => (this.perfis = data))
			}

			async function criarUsuario() {
				//Reseta msg de erro
				this.error = ref('')

				if (!senhaOk.value) {
					this.error = 'Senha inválida!'
					return
				}

				if (!emailOk.value) {
					this.error = 'E-mail inválido!'
					return
				}

				if (
					!verified.value &&
					(tokenCaptcha.value == null || tokenCaptcha.value == '')
				) {
					alert('Oops, você tem de checar o Captcha')
					return
				}

				let response = await SgaaApplication.usuarios(
					this.email,
					this.passwd,
					this.perfil
				)

				if (response.status === 200) {
					let credential = {
						email: this.email,
						password: this.passwd,
					}

					console.log('credential response = ', credential)

					enviarEmailUsuarioCadastro(credential)

					console.log('após enviar email')
					alert('Usuário ' + credential.email + ' cadastrado com sucesso !')
					//navigating
					router.push('/efetuarLogin')
				}
			}

			function onVerify(tokenStr, ekey) {
				verified.value = true
				tokenCaptcha.value = tokenStr
				eKey.value = ekey
			}

			function onExpire() {
				verified.value = false
				tokenCaptcha.value = null
				eKey.value = null
				expired.value = true
			}

			function onMouseLeaveEmail() {
				const mensagem = document.getElementById('msgEmail')
				if (this.email == '') {
					mensagem.textContent = ''
				}
			}

			function onMouseLeaveSenha() {
				const mensagem = document.getElementById('msgSenha')
				if (this.passwd == '') {
					mensagem.textContent = ''
				}
			}

			function onChallengeExpire() {
				verified.value = false
				tokenCaptcha.value = null
				eKey.value = null
				expired.value = true
			}

			function onError(err) {
				tokenCaptcha.value = null
				eKey.value = null
				error.value = err
			}

			watch(email, (newEmail) => {
				const mensagem = document.getElementById('msgEmail')

				// Verificar se o e-mail é valido de acordo com a especificação W3C HTML5
				if (
					!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
						newEmail
					)
				) {
					// Altere a cor do parágrafo
					paragraphColorEmail.value = 'red'
					mensagem.textContent = 'O e-mail informado é inválido!'
					return
				}

				// Altere a cor do parágrafo
				paragraphColorEmail.value = 'green'
				// Se todas as verificações passarem, a senha é válida
				mensagem.textContent = 'O e-mail é válido.'
				emailOk.value = true
			})

			watch(passwd, (newPasswd) => {
				const mensagem = document.getElementById('msgSenha')

				// Verificar o comprimento da senha
				if (newPasswd.length < 8) {
					// Altere a cor do parágrafo
					paragraphColorPasswd.value = 'red'
					mensagem.textContent = 'A senha deve ter pelo menos 8 caracteres.'
					return
				}

				// Verificar se a senha contém pelo menos um número
				if (!/\d/.test(newPasswd)) {
					// Altere a cor do parágrafo
					paragraphColorPasswd.value = 'red'
					mensagem.textContent = 'A senha deve conter pelo menos um número.'
					return
				}

				// Verificar se a senha contém pelo menos uma letra maiúscula
				if (!/[A-Z]/.test(newPasswd)) {
					// Altere a cor do parágrafo
					paragraphColorPasswd.value = 'red'
					mensagem.textContent =
						'A senha deve conter pelo menos uma letra maiúscula.'
					return
				}

				// Verificar se a senha contém pelo menos uma letra maiúscula
				if (!/\S+$/.test(newPasswd)) {
					// Altere a cor do parágrafo
					paragraphColorPasswd.value = 'red'
					mensagem.textContent = 'A senha não pode conter espaços em branco.'
					return
				}

				// Altere a cor do parágrafo
				paragraphColorPasswd.value = 'green'
				// Se todas as verificações passarem, a senha é válida
				mensagem.textContent = 'A senha é válida.'
				senhaOk.value = true
			})

			return {
				email,
				passwd,
				perfil,
				perfis,
				token,
				criarUsuario,
				carregaCbPerfis,
				verified,
				expired,
				tokenCaptcha,
				eKey,
				error,
				senhaOk,
				emailOk,
				onVerify,
				onExpire,
				onChallengeExpire,
				onMouseLeaveSenha,
				onMouseLeaveEmail,
				paragraphColorEmail,
				paragraphColorPasswd,
				onError,
			}
		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	h3 {
		margin: 40px 0 0;
		color: #000000;
	}

	ul {
		list-style-type: none;
		padding: 0;
	}

	li {
		display: inline-block;
		margin: 0 10px;
	}

	a {
		color: #42b983;
	}

	/*Bootstrap: Mudança da cor dos botões*/
	.btn-info {
		background-color: #3276b1;
		border-color: #3276b1;
		color: #ffffff;
	}

	/*Bootstrap: Mudança da cor dos botões*/
	.btn-success {
		background-color: #58b358;
		border-color: #58b358;
	}

	/*Evita a geração de scroll horizontal*/
	.row {
		margin-right: 0px;
		margin-left: 0px;
		margin-top: 15px;
	}

	.row-margin-top {
		margin-top: 15px;
	}

	body {
		font-family: 'Avenir', Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	#App {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
	}

	#verified {
		color: white;
		background: green;
	}

	#error {
		color: white;
		background: red;
	}

	#divMsgError {
		text-align: left;
		color: #f30606;
	}

	p {
		text-align: justify;
		background-color: #f8f4f4;
		border: none;
		margin: 0;
		padding: 0;
	}

	.gap-30 {
		width: 100%;
		height: 30px;
	}

	.gap-20 {
		width: 100%;
		height: 20px;
	}

	.alert-info {
		background-image: -webkit-linear-gradient(top, #d9edf7 0, #b9def0 100%);
		background-image: -o-linear-gradient(top, #d9edf7 0, #b9def0 100%);
		background-image: -webkit-gradient(
			linear,
			left top,
			left bottom,
			from(#d9edf7),
			to(#b9def0)
		);
		background-image: linear-gradient(to bottom, #d9edf7 0, #b9def0 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9edf7', endColorstr='#ffb9def0', GradientType=0);
		background-repeat: repeat-x;
		border-color: #9acfea;
	}

	.alert {
		/* text-shadow: 0 1px 0 rgba(255,255,255,.2); */
		-webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25),
			0 1px 2px rgba(0, 0, 0, 0.05);
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25),
			0 1px 2px rgba(0, 0, 0, 0.05);
	}

	.alert-info {
		color: #31708f;
		background-color: #d9edf7;
		border-color: #bce8f1;
	}

	.alert {
		padding: 15px;
		margin-bottom: 20px;
		border: 1px solid transparent;
		border-radius: 4px;
	}
</style>

<style src="@vueform/multiselect/themes/default.css"></style>
