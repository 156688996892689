import { createWebHistory, createRouter } from 'vue-router'
import Home from '@/components/Home'
import Dados from '../components/Dados'
import Suporte from '../components/Suporte'
import Acesso from '../components/Acesso'
import PedToken from '@/components/Acesso/PedToken'
import Faq from '../components/Suporte/Faq'
import TermoUso from '../components/Suporte/TermoUso'
import AcumuladosReq from '@/components/Obtencao_dados/AcumuladosReq'
import ConsultaDadosRecentes from '@/components/Obtencao_dados/ConsultaDadosRecentes'
import AgendarConsultaDadosHistoricos from '@/components/Obtencao_dados/AgendarConsultaDadosHistoricos'
import EfetuarLogin from '@/components/EfetuarLogin'
import ConsultaRedeEstacoes from '@/components/ConsultaRedeEstacoes'
import AgendaReq from '@/components/AgendaReq'
import CadastroUsuario from '@/components/CadastroUsuario'
import CadastroUsuarioAdm from '@/components/CadastroUsuarioAdm'
import ListaSensores from '@/components/ListaSensores'
import AlterarSenhaUsuario from '@/components/Acesso/AlterarSenhaUsuario'
import AtualizaPerfilUsuario from '@/components/AtualizaPerfilUsuario'
import PaginaSucesso from '@/components/PaginaSucesso'
import PaginaErro from '@/components/PaginaErro'
import NotFound from '@/components/NotFound'

const routes = [
	{
		path: '/home',
		name: 'Home',
		component: Home,
	},
	{
		path: '/dados',
		name: 'Dados',
		component: Dados,
	},
	{
		path: '/dados/acumulados',
		name: 'Acumulados',
		component: AcumuladosReq,
	},
	{
		path: '/dados/consultarDadosRecentes',
		name: 'ConsultaDadosRecentes',
		component: ConsultaDadosRecentes,
	},
	{
		path: '/dados/agendarConsultaDadosHistoricos',
		name: 'AgendarConsultaDadosHistoricos',
		component: AgendarConsultaDadosHistoricos,
	},
	{
		path: '/suporte',
		name: 'Suporte',
		component: Suporte,
	},
	{
		path: "/suporte/termouso",
		name: "TermoUso",
		component: TermoUso
	},
	{
		path: '/suporte/faq',
		name: 'Faq',
		component: Faq,
	},
	{
		path: '/acesso',
		name: 'Acesso',
		component: Acesso,
	},
	{
		path: "/acesso/pedToken",
		name: "PedToken",
		component: PedToken
	},
	{
		path: '/acesso/alterarSenhaUsuario',
		name: 'AlterarSenhaUsuario',
		component: AlterarSenhaUsuario,
	},
	{
		path: '/efetuarLogin',
		name: 'EfetuarLogin',
		component: EfetuarLogin,
	},
	{
		path: '/consultarRedeEstacoes',
		name: 'ConsultaRedeEstacoes',
		component: ConsultaRedeEstacoes,
	},
	{
		path: '/agendarReq',
		name: 'Agenda',
		component: AgendaReq,
	},
	{
		path: '/cadastrarUsuario',
		name: 'CadastroUsuario',
		component: CadastroUsuario,
	},
	{
		path: '/cadastrarUsuarioAdm',
		name: 'CadastroUsuarioAdm',
		component: CadastroUsuarioAdm,
	},
	{
		path: '/listarSensores',
		name: 'Sensores',
		component: ListaSensores,
	},
	{
		path: '/atualizarPerfilUsuario',
		name: 'AtualizaPerfilUsuario',
		component: AtualizaPerfilUsuario,
	},
	{
		path: '/paginaSucesso',
		name: 'PaginaSucesso',
		component: PaginaSucesso,
	},
	{
		path: '/paginaErro',
		name: 'PaginaErro',
		component: PaginaErro,
	},
	{
		path: '/:catchAll(.*)',
		component: NotFound,
	},
]

const router = createRouter({
	history: createWebHistory(),
	routes,
})

export default router
