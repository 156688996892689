<template>
	<!-- Main Content -->
	<div class="container">
		<h3 class="p-3 text-center">Sensores das Estações da Rede do Cemaden</h3>
		<!-- Este div serve para consulta de dados acumulados -->
		<form id="formMatrix">
			<div class="gap-30"></div>
			<div id="divTpEstacao" class="form-group row">
				<div class="row">
					<label for="selectEstacao" class="col-sm-2 col-form-label"
						>Tipo de Estação</label
					>
					<div class="col-sm-4">
						<select
							class="form-select col-sm-4"
							id="selectEstacao"
							v-model="tpEstacao"
							v-on:click.once="carregaCbTpEstacoes"
						>
							<option value="">Selecione o tipo de estação</option>
							<option
								v-for="(tpEstacao, index) in tpEstacoes"
								:value="tpEstacao.tipoestacao"
								:key="index"
							>
								{{ tpEstacao.tipoestacaodescricao }}
							</option>
						</select>
					</div>
				</div>
			</div>
			<div class="gap-30"></div>
			<div id="divButton" class="form-group row">
				<div class="row">
					<div class="col-sm-2">
						<button
							id="buttonMatrix"
							@click.prevent="buscarSensores"
							type="submit"
							class="btn btn-sm btn-info"
						>
							Buscar
						</button>
					</div>
				</div>
			</div>
			<div class="gap-60"></div>
			<div id="respSensores">
				<table
					class="table table-hover table-responsive-sm"
					style="width: 90%"
					id="tb_listasensores"
				></table>
			</div>
		</form>
	</div>
</template>

<script>
	import { ref } from 'vue'
	import 'bootstrap/dist/css/bootstrap.min.css'
	import 'jquery/dist/jquery.min.js'
	import $ from 'jquery'
	import SwsApplication from '@/sws.application.js'
	//Datatable Modules
	import "datatables.net-dt/js/dataTables.dataTables"
	import "datatables.net-dt/css/jquery.dataTables.min.css"

	import "datatables.net";
	import "datatables.net-buttons";
	
	export default {
		name: 'ListaSensores',
		setup() {
			let tpEstacao = ref('')
			let tpEstacoes = ref([])

			function carregaCbTpEstacoes() {
				SwsApplication.pcdsTipoEstacaoSensores().then(
					(data) => (this.tpEstacoes = data)
				)
			}

			function buscarSensores() {
				let params = {}

				if (this.tpEstacao) {
					params.tipoestacao = this.tpEstacao
				}

				$(async function () {
					let data = await SwsApplication.pcdsTipoEstacaoSensores(
						params.tipoestacao
					)
					data = data[0].sensor

					$('#tb_listasensores').DataTable({
						data: data,
						language: {
							emptyTable:
								'Nenhum resultado foi encontrado para essa requisição!',
						},
						destroy: true,
						paging: true,
						searching: true,
						processing: true,
						dom: 'Bfrtip',
						buttons: ['copy', 'csv'],
						columnDefs: [
							{
								targets: '_all',
								defaultContent: '-',
								className: 'dt-body-left',
							},
						],
						columns: [
							{
								data: 'sensor',
								title: 'Código',
							},
							{
								data: 'sensordescricao',
								title: 'Descrição',
							},
						],
					})
				})
			}

			return {
				tpEstacao,
				tpEstacoes,
				buscarSensores,
				carregaCbTpEstacoes,
			}
		},
		mounted() {
			console.log('Sucesso')
		},
		beforeUnmount() {
			console.log('unmount')

			if ($.fn.DataTable.isDataTable('#tb_listasensores')) {
				if ($('#tb_listasensores').DataTable() != null) {
					var tableId = '#tb_listasensores'

					$('#tb_listasensores').DataTable().clear()
					$('#tb_listasensores').DataTable().destroy()
					//2nd empty html
					$(tableId + ' tbody').empty()
					$(tableId + ' thead').empty()
					$('#tb_listasensores').empty()
				}
			}
		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	h3 {
		margin: 40px 0 0;
		color: #000000;
	}

	ul {
		list-style-type: none;
		padding: 0;
	}

	li {
		display: inline-block;
		margin: 0 10px;
	}

	a {
		color: #42b983;
	}

	.search-result {
		float: left;
		margin-top: 45px;
		text-align: left;
	}

	.search-result-error {
		float: left;
		margin-top: 45px;
		text-align: center;
	}

	/*Bootstrap: Mudança da cor dos botões*/
	.btn-primary {
		background-color: #000000;
		border-color: #000000;
	}

	/*Bootstrap: Mudança da cor dos botões*/
	.btn-info {
		background-color: #3276b1;
		border-color: #3276b1;
		color: #ffffff;
	}

	/*Evita a geração de scroll horizontal*/
	.row {
		margin-right: 0px;
		margin-left: 0px;
	}

	.gap-60 {
		width: 100%;
		height: 60px;
	}

	.gap-40 {
		width: 100%;
		height: 40px;
	}

	.gap-30 {
		width: 100%;
		height: 30px;
	}

	.gap-20 {
		width: 100%;
		height: 20px;
	}

	.row-margin-top {
		margin-top: 10px;
	}
</style>

<style src="@vueform/multiselect/themes/default.css"></style>
