<template>

	<!-- Main Content -->
	<div class="container">		
		<!-- Este div serve para consulta de dados acumulados -->
		<form id="formMatrix">
			<div class="gap-30"></div>
			<p><strong>Token do usuário para utilização no Catálodo de Serviços</strong></p>
			<div id="divToken" class="form-group row">
				<div class="gap-30"></div>
				<div class="row">
					<!-- <label for="tokenTxtArea" class="col-sm-2 col-form-label">Token:</label> -->
					<div class="cols-sm-4">
					</div>
					<div class="cols-sm-4">						
						<textarea id="tokenTxtArea" v-model="token" readonly></textarea>			
						<!-- <button id="btnToken" @click.prevent="copyToClipboard" class="btn btn-sm btn-info">Copiar para o clipboard</button> -->
					</div>
				</div>
				<div class="row">
					<div class="cols-sm-4">
						<button id="btnToken" @click.prevent="copyToClipboard" class="btn btn-sm btn-info">Copiar para o clipboard</button>
					</div>
				</div>
			</div>			
		</form>
	</div>

</template>

<script>

import { ref } from 'vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import $ from 'jquery';
import { useLoginStore } from '../../stores/LoginStore';


export default {
	name: 'PedToken',
	setup() {
		const loginStore = useLoginStore()

		let token = ref(loginStore.token)


		const copyToClipboard = () => {
			const textarea = document.getElementById('tokenTxtArea');
			textarea.select();
			textarea.setSelectionRange(0, 99999); // Para dispositivos móveis

			// Copiar o texto para a área de transferência
			document.execCommand('copy');
			alert('Text copied to clipboard');
		};

		return {
			token,
			copyToClipboard
		}
	},
	mounted() {
		console.log('entrou mounted')
		$('#tokenTxtArea').val(this.token)
		console.log('Sucesso')
	}	
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
textarea {
	width: 80%;
	height: 150px;
	padding: 12px 20px;
	box-sizing: border-box;
	border: 2px solid #ccc;
	border-radius: 4px;
	background-color: #f8f8f8;
	font-size: 16px;
}

/*Bootstrap: Mudança da cor dos botões*/
.btn-info {
	background-color: #3276B1;
	border-color: #3276B1;
	color: #ffffff; 
}

/*Evita a geração de scroll horizontal*/
.row {
	margin-right: 0px;
	margin-left: 0px;
}

.gap-60 {
	width: 100%;
	height: 60px;
}

.gap-40 {
	width: 100%;
	height: 40px;
}

.gap-30 {
	width: 100%;
	height: 30px;
}

.gap-20 {
	width: 100%;
	height: 20px;
}

.row-margin-top {
	margin-top: 10px;
}
</style>

<style src="@vueform/multiselect/themes/default.css">

</style>
