export function validaSenha(senha) {
    //const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])(?!.*\s).{8,}$/;
    const regex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=\S+$).{8,}$/;
    let res = regex.test(senha);
    return res;
}

export function gerarSenhaAlfanumerica(tamanho) {
	const caracteresPermitidos =
		'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
	let senha = ''

	for (let i = 0; i < tamanho; i++) {
		const randomIndex = Math.floor(Math.random() * caracteresPermitidos.length)
		senha += caracteresPermitidos[randomIndex]
	}

	return senha
}
