<template>
	<div id="divObs" class="container">
		<div class="home-route" align="center">
			<h1>{{ home_msg }}</h1>

			<div class="row">
				<div class="col-lg-9" align="center">
					<p>
						Nessa plataforma você tem acesso aos dados que são coletados pelas
						Rede Observacional do Cemaden.
					</p>

					<ol>
						<li class="list-group-item">
							Utilize o menu de navegação, localizado na parte superior da tela,
							para escolher entre as diversas opções de visualização e acesso
							aos dados do Centro. Registra-se que usuários dos dados da rede de
							monitoramento de chuvas deverão mencionar a FONTE dos DADOS, como
							seja,
							<strong>DADOS DA REDE OBSERVACIONAL DO CEMADEN/MCTIC</strong>
							todas as vezes que utilizarem os dados do Centro.
						</li>
					</ol>
				</div>
			</div>

			<div class="row">
				<label class="col-lg-8">
					<h2>Regras de Utilização</h2>
				</label>

				<div class="col-lg-9" align="center">
					<p>
						Prezado usuário, abaixo estão dispostas algumas regras de utilização
						dos serviços disponibilizados pelo Cemaden:
					</p>

					<ol>
						<li class="list-group-item">
							Em relação à taxa de utilização dos serviços, você poderá efetuar
							até 12 requisições por minuto caso seja um usuário externo ou 180
							requisições por minuto no caso de parceiros do centro. Sempre que
							ultrapassar este limite, seu usuário será bloqueado
							temporariamente até que o intervalo de tempo esteja completo.
							Depois do tempo requerido, você poderá acessar normalmente os
							serviços disponibilizados pela plataforma, não sendo necessário
							criar nova conta.
						</li>
						<li class="list-group-item">
							Por questões de segurança, caso a sua conta esteja inativa por
							mais de um ano, esta será automaticamente removida pelo sistema.
							Nesse caso, para acessar novamente a plataforma será necessário
							realizar um novo cadastro de usuário.
						</li>

						<li class="list-group-item">
							Os dados históricos solicitados (requisições agendadas) ficarão
							disponíveis para download na plataforma por apenas 30 dias. Após
							este período, caso necessário, o usuário deverá realizar uma nova
							solicitação desses dados.
						</li>
					</ol>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'home',
		data() {
			return {
				home_msg: 'Bem Vindo à Plataforma de Entrega de Dados - PED!',
			}
		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	/*Evita a geração de scroll horizontal*/
	.row {
		margin-right: 0px;
		margin-left: 0px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.row-margin-top {
		margin-top: 20px;
	}

	.container {
		height: 100vh;
		width: 100vw;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		vertical-align: middle;
	}

	h1,
	h2 {
		font-weight: bold;
	}

	p {
		color: #000;
		font-size: 24px;
		width: 85%;
		padding: 30px;
	}

	ul {
		padding: 0;
	}

	li {
		text-align: justify;
		font-size: 18px;
		margin: auto;
		width: 100%;
		padding: 20px;
	}

	a {
		color: #42b983;
	}

	.home-route {
		padding: 20px;
	}

	p {
		padding: 10px;
	}
</style>
