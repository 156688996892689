<template>
	<!-- Main Content -->
	<div class="container">
		<h3 class="p-3 text-center">Consulta de Dados da Rede de Estações</h3>
		<!-- Este div serve para consulta de dados acumulados -->
		<form id="formMatrix">
			<div class="form-group">
				<div class="row">
					<div id="divEstado" class="col-3">
						<label for="selectUF">UF</label>
						<select
							class="form-select"
							id="selectUF"
							v-model="uf"
							v-on:change="carregaCbMunicipios"
						>
							<option value="">Selecione o estado</option>
							<option v-for="(uf, index) in ufs" :value="uf" :key="index">
								{{ uf }}
							</option>
						</select>
					</div>

					<div id="divCity" class="col-4">
						<label for="selectCity">Município</label>
						<select
							class="form-select"
							id="selectCity"
							v-model="municipio"
							v-on:change="carregaCbEstacoes"
						>
							<option value="">Selecione o município</option>
							<option
								v-for="(municipio, index) in municipios"
								:value="municipio.codibge"
								:key="index"
							>
								{{ municipio.cidade }}
							</option>
						</select>
					</div>
				</div>

				<div class="gap-40"></div>

				<div class="row">
					<div id="divTpEstacao" class="col-3">
						<label for="selectTpEstacao">Tipo de Estação</label>
						<select
							class="form-select"
							id="selectTpEstacao"
							v-model="tpEstacao"
							v-on:click.once="carregaCbTpEstacoes"
							v-on:change="filtraCbEstacoesPorTipo"
						>
							<option value="">Selecione o tipo de estação</option>
							<option
								v-for="(tpEstacao, index) in tpEstacoes"
								:value="tpEstacao.tipoestacao"
								:key="index"
							>
								{{ tpEstacao.tipoestacaodescricao }}
							</option>
						</select>
					</div>

					<div id="divStation" class="col-5">
						<label for="selectStation">Estação</label>
						<select class="form-select" id="selectStation" v-model="estacao">
							<option value="">Selecione a estação</option>
							<option
								v-for="(estacao, index) in estacoes"
								:value="estacao"
								:key="index"
							>
								{{ estacao.nome === '' ? ' - ' : estacao.nome + ' - ' }}
								{{ estacao.codestacao }}
							</option>
						</select>
					</div>
				</div>
			</div>

			<div class="gap-40"></div>

			<div id="divButton" class="form-group">
				<div class="row">
					<div class="col-1">
						<button
							id="btnBuscarDadosRedeEstacoes"
							@click.prevent="buscarDadosRedeEstacoes"
							type="submit"
							class="btn btn-sm btn-info"
						>
							Consultar
						</button>
					</div>
				</div>
			</div>

			<div class="gap-10"></div>
		</form>
	</div>

	<div class="gap-40"></div>

	<table
		class="table table-hover table-responsive-sm"
		style="width: 90%"
		id="tb_dadosredeEstacoes"
	></table>

	<div class="gap-40"></div>
</template>

<script>
	import { ref } from 'vue'
	import 'bootstrap/dist/css/bootstrap.min.css'
	import 'jquery/dist/jquery.min.js'
	import $ from 'jquery'
	import SwsApplication from '@/sws.application.js'

	//Datatable Modules
	import "datatables.net-dt/js/dataTables.dataTables"
	import "datatables.net-dt/css/jquery.dataTables.min.css"
	import "datatables.net";
	import "datatables.net-buttons";
	//import "datatables.net-responsive-dt/css/responsive.dataTables.min.css"
	import "datatables.net/js/jquery.dataTables.min.js"
	//import "datatables.net-responsive/js/dataTables.responsive.min.js"
	import "datatables.net-buttons/js/dataTables.buttons.js"
	import "datatables.net-buttons/js/buttons.colVis.js"
	import "datatables.net-buttons/js/buttons.html5.js"
	import "datatables.net-buttons/js/buttons.print.js"

	export default {
		name: 'ConsultaRedesEstacoes',
		setup() {
			let uf = ref('')
			let ufs = ref([
				'AC',
				'AL',
				'AM',
				'AP',
				'BA',
				'CE',
				'DF',
				'ES',
				'GO',
				'MA',
				'MG',
				'MS',
				'MT',
				'PA',
				'PB',
				'PE',
				'PI',
				'PR',
				'RJ',
				'RN',
				'RO',
				'RR',
				'RS',
				'SC',
				'SE',
				'SP',
				'TO',
			])
			let municipio = ref('')
			let municipios = ref([])
			let estacao = ref('')
			let estacoes = ref([])
			let todasEstacoesMunicipio = ref([])
			let tpEstacoes = ref([])
			let tpEstacao = ref('')

			function carregaCbMunicipios() {
				if (this.uf) {
					//Reseta o combo de estações
					this.estacoes = ref([])
					//Reseta o valor inicial do combo de Estação
					this.estacao = ref('')
					//Reseta o valor inicial do combo de Tipo de Estação
					this.tpEstacao = ref('')
					// Carrega combo de municípios da UF selecionada
					SwsApplication.cidades(this.uf).then(
						(data) => (this.municipios = data)
					)
				} else {
					//Reseta o valor inicial do combo de Município
					this.municipio = ref('')
					//Reseta o combo de municípios
					this.municipios = ref([])
					//Reseta o valor inicial do combo de Estação
					this.estacao = ref('')
					//Reseta o combo de estações
					this.estacoes = ref([])
					//Reseta o valor inicial do combo de Tipo de Estação
					this.tpEstacao = ref('')
				}
			}

			function carregaCbEstacoes() {
				if (this.municipio) {
					SwsApplication.estacoes(this.municipio).then((data) => {
						this.estacoes = data
						this.todasEstacoesMunicipio = this.estacoes
					})
				} else {
					//Reseta o valor inicial do combo de Estação
					this.estacao = ref('')
					//Reseta o combo de estações
					this.estacoes = ref([])
					//Reseta o valor inicial do combo de Tipo de Estação
					this.tpEstacao = ref('')
				}
			}

			function carregaCbTpEstacoes() {
				SwsApplication.pcdsTipoEstacaoSensores().then(
					(data) => (this.tpEstacoes = data)
				)
			}

			function filtraCbEstacoesPorTipo() {
				this.estacoes = this.todasEstacoesMunicipio

				if (this.tpEstacao != 0) {
					//loop em this.estacoes para filtrar por tipo de estacao
					let estacoesFiltered = this.estacoes.filter(
						(estacao) => estacao.id_tipoestacao == this.tpEstacao
					)
					this.estacoes = estacoesFiltered
				}
			}

			/* Formatting function for row details */
			function format(d) {
				// `d` is the original data object for the row
				return (
					'<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">' +
					'<tr>' +
					'<td>Nome:</td>' +
					'<td>' +
					d.nome +
					'</td>' +
					'</tr>' +
					'<tr>' +
					'<td>Latitude:</td>' +
					'<td>' +
					d.latitude +
					'</td>' +
					'</tr>' +
					'<tr>' +
					'<td>Longitude:</td>' +
					'<td>' +
					d.longitude +
					'</td>' +
					'</tr>' +
					'<tr>' +
					'<td>Cidade:</td>' +
					'<td>' +
					d.cidade +
					'</td>' +
					'</tr>' +
					'<tr>' +
					'<td>UF:</td>' +
					'<td>' +
					d.uf +
					'</td>' +
					'</tr>' +
					'<tr>' +
					'<td>Tipo:</td>' +
					'<td>' +
					d.tipoestacao_descricao +
					'</td>' +
					'</tr>' +
					'<tr>' +
					'<td>Altitude (m):</td>' +
					'<td>' +
					d.altitude +
					'</td>' +
					'</tr>' +
					'<tr>' +
					'<td>Data Instalação:</td>' +
					'<td>' +
					d.data_instalacao +
					'</td>' +
					'</tr>' +
					'<tr>' +
					'<td>Data Cadastro:</td>' +
					'<td>' +
					d.dh_cadastro +
					'</td>' +
					'</tr>' +
					'<tr>' +
					'<td>Rede:</td>' +
					'<td>' +
					d.rede_sigla +
					'</td>' +
					'</tr>' +
					'<tr>' +
					'<td>Offset:</td>' +
					'<td>' +
					(d.offset != null ? d.offset : '-') +
					'</td>' +
					'</tr>' +
					'<tr>' +
					'<td>Cota Alerta:</td>' +
					'<td>' +
					(d.cota_alerta != null ? d.cota_alerta : '-') +
					'</td>' +
					'</tr>' +
					'<tr>' +
					'<td>Cota Atenção:</td>' +
					'<td>' +
					(d.cota_atencao != null ? d.cota_atencao : '-') +
					'</td>' +
					'</tr>' +
					'<tr>' +
					'<td>Cota Transbordamento:</td>' +
					'<td>' +
					(d.cota_transbordamento != null ? d.cota_transbordamento : '-') +
					'</td>' +
					'</tr>' +
					'</table>'
				)
			}

			function buscarDadosRedeEstacoes() {
				let params = {}

				if (this.uf) {
					params.uf = this.uf
				}
				if (this.municipio) {
					params.codibge = this.municipio
				}
				if (this.tpEstacao) {
					params.tipoestacao = this.tpEstacao
				}
				if (this.estacao.codestacao) {
					params.codestacao = this.estacao.codestacao
				}

				$(async function () {
					let data = await SwsApplication.redeEstacoes(
						params.codestacao,
						params.codibge,
						params.uf,
						params.tipoestacao
					)

					var table = $('#tb_dadosredeEstacoes').DataTable({
						data: data,
						language: {
							emptyTable:
								'Nenhum resultado foi encontrado para essa requisição!',
						},
						destroy: true,
						paging: true,
						searching: true,
						processing: true,
						autoWidth: false,
						dom: 'Bfrtip',
						buttons: ['copy', 'csv', 'print'],
						columnDefs: [
							{
								targets: '_all',
								defaultContent: '-',
								className: 'dt-body-left',
							},
						],
						columns: [
							{
								className: 'dt-control',
								orderable: false,
								data: null,
								defaultContent: '',
							},
							{
								data: 'codestacao',
								title: 'Código',
							},
							{
								data: 'nome',
								title: 'Nome',
							},
							{
								data: 'latitude',
								title: 'Latitude',
							},
							{
								data: 'longitude',
								title: 'Longitude',
							},
						],
					})
					
					$(document).ready(function() {
						table.columns.adjust().responsive.recalc()
					});

					$('#tb_dadosredeEstacoes tbody').off('click', 'td.dt-control')

					// Add event listener for opening and closing details
					$('#tb_dadosredeEstacoes tbody').on(
						'click',
						'td.dt-control',
						function () {
							var tr = $(this).closest('tr')
							var row = table.row(tr)

							if (row.child.isShown()) {
								// This row is already open - close it
								row.child.hide()
								tr.removeClass('shown')
							} else {
								// Open this row
								row.child(format(row.data())).show()
								tr.addClass('shown')
							}
						}
					)
				})
			}

			return {
				uf,
				ufs,
				municipio,
				municipios,
				estacao,
				estacoes,
				todasEstacoesMunicipio,
				tpEstacao,
				tpEstacoes,
				carregaCbMunicipios,
				carregaCbEstacoes,
				carregaCbTpEstacoes,
				filtraCbEstacoesPorTipo,
				buscarDadosRedeEstacoes,
			}
		},
		mounted() {
			console.log('Sucesso')
		},
		beforeUnmount() {
			console.log('unmount')

			if ($.fn.DataTable.isDataTable('#tb_dadosredeEstacoes')) {
				if ($('#tb_dadosredeEstacoes').DataTable() != null) {
					var tableId = '#tb_dadosredeEstacoes'

					$('#tb_dadosredeEstacoes').DataTable().clear()
					$('#tb_dadosredeEstacoes').DataTable().destroy()
					//window.removeEventListener('resize', this.someMethod)

					//2nd empty html
					$(tableId + ' tbody').empty()
					$(tableId + ' thead').empty()
					$('#tb_dadosredeEstacoes').empty()
				}
			}
		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	h3 {
		margin: 40px 0 0;
		color: #000000;
	}

	ul {
		list-style-type: none;
		padding: 0;
	}

	li {
		display: inline-block;
		margin: 0 10px;
	}

	a {
		color: #42b983;
	}

	.search-result {
		float: left;
		margin-top: 45px;
		text-align: left;
	}

	.search-result-error {
		float: left;
		margin-top: 45px;
		text-align: center;
	}

	/*Bootstrap: Mudança da cor dos botões*/
	.btn-primary {
		background-color: #000000;
		border-color: #000000;
	}

	/*Bootstrap: Mudança da cor dos botões*/
	.btn-info {
		background-color: #3276b1;
		border-color: #3276b1;
		color: #ffffff;
	}

	/*Evita a geração de scroll horizontal*/
	.row {
		margin-right: 0px;
		margin-left: 0px;
	}

	.row-margin-top {
		margin-top: 10px;
	}

	.divData {
		float: left;
		padding-top: 2em;
		padding-bottom: 2em;
	}

	.divDtAtual {
		float: left;
		padding-right: 2em;
		padding-top: 2em;
		padding-bottom: 2em;
		border-radius: 0.5em;
	}

	.divDtHistorica {
		float: left;
		padding-right: 2em;
		padding-top: 2em;
		padding-bottom: 2em;
		border-radius: 0.5em;
	}

	.divObs {
		float: left;
		padding-right: 2em;
		padding-top: 2em;
		padding-bottom: 2em;
		border-radius: 0.5em;
	}

	.gap-40 {
		width: 100%;
		height: 40px;
	}

	.gap-30 {
		width: 100%;
		height: 30px;
	}

	.gap-20 {
		width: 100%;
		height: 20px;
	}

	.gap-10 {
		width: 100%;
		height: 10px;
	}
</style>
