<template>
	<div id="app">
		<Navbar />
		<router-view />
	</div>
</template>

<script>
	import Navbar from '@/components/Navbar'

	export default {
		name: 'App',
		components: {
			Navbar,
		},
	}
</script>

<style>
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}

	body {
		color: #b4b8bd;
		font-family: 'montserrat', sans-serif;
	}

	header {
		width: 100vw;
		padding: 25px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		background-color: rgb(70, 163, 192);
		padding: 20px 20px;
	}

	#logo {
		width: 150px;
	}

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #202427;
	}

	#nav {
		padding: 30px;
	}

	#nav a {
		font-weight: bold;
		color: #2c3e50;
	}

	#nav a.router-link-exact-active {
		color: #42b983;
	}
</style>
