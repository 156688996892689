<template>
	<div class="header" style="max-width: 100%">
		<div class="header-img" style="max-width: 100%">
			<a
				href="http://ped.cemaden.gov.br"
				target="_self"
				style="max-width: 100%"
			>
				<!--
                 <img id="logo" src="@/assets/cemaden_completa_vertical_colorida.svg" alt="Cemaden">
                
                <img src="https://www.gov.br/cemaden/pt-br/centrais-de-conteudo/imagens/banner-abertura-desktop2.jpg/@@images/image"
                    alt="banner-abertura-desktop2.jpg">
                -->

				<img
					src="@/assets/banner_ped_v0_red.png"
					alt="PED - Plataforma de Entrega de Dados"
					style="max-width: 100%"
				/>
			</a>
		</div>
	</div>
	<nav id="vue">
		<div class="menu-item" v-if="loginStore.logado" id="home">
			<router-link to="/home" class="nav-item nav-link">Home</router-link>
		</div>

		<Dados v-if="loginStore.logado" title="Obtenção de Dados" />

		<div class="menu-item" v-if="loginStore.logado" id="agendarReq">
			<router-link to="/AgendarReq" class="nav-item nav-link"
				>Status de Agendamento de Dados Históricos</router-link
			>
		</div>

		<div class="menu-item" v-if="loginStore.usuarioAdm" id="cadUsuarioAdm">
			<router-link to="/CadastrarUsuarioAdm" class="nav-item nav-link"
				>Cadastro de Usuário ADM</router-link
			>
		</div>
		<div class="menu-item" v-if="loginStore.logado" id="listarSensores">
			<router-link to="/ListarSensores" class="nav-item nav-link"
				>Lista de Sensores</router-link
			>
		</div>
		<div
			class="menu-item"
			v-if="loginStore.usuarioAdm"
			id="atualizarPerfilUsuario"
		>
			<router-link to="/AtualizarPerfilUsuario" class="nav-item nav-link"
				>Atualiza Perfil de Usuário</router-link
			>
		</div>
		<div class="menu-item" v-if="loginStore.logado" id="catalogo">
			<a
				class="nav-item nav-link"
				id="catalogo"
				href="http://sws.cemaden.gov.br"
				target="_blank"
				>Catálogo de Serviços</a
			>
		</div>
		<div
			class="menu-item"
			v-if="loginStore.logado && loginStore.temRequisicaoConcluida"
			id="notificação"
		>
			<a class="nav-item nav-link" id="notificacao">
				<div
					v-if="loginStore.temRequisicaoConcluida"
					id="notificacaoAgendamento"
				>
					<a href="#" @click="buscarReqAgendadas">
						<img src="@/assets/alarm_bell_red2.png" align="center" />
					</a>
					<div>
						<div
							ref="modal"
							class="modal fade modal-xl"
							:class="{ show: active, 'd-block': active }"
							tabindex="-1"
							role="dialog"
						>
							<div
								class="modal-dialog modal-xl modal-center"
								style="max-width: 100%; width: auto; display: table"
								role="document"
							>
								<div class="modal-content">
									<div class="modal-header">
										<h5 class="modal-title">
											Consultar Agendamentos de Dados Históricos Concluídos
										</h5>
										<button
											type="button"
											class="close"
											data-dismiss="modal"
											aria-label="Close"
											@click="buscarReqAgendadas"
										>
											<span aria-hidden="true">&times;</span>
										</button>
									</div>
									<div class="modal-body">
										<div v-if="exibeTabelaAgendamento" class="container">
											<table
												class="table table-hover table-responsive-lg"
												style="width: 90%"
												id="tb_agendamentos_concluidos"
											></table>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-if="active" class="modal-backdrop fade show"></div>
					</div>
				</div>
			</a>
		</div>

		<Suporte v-if="loginStore.logado" title="Suporte" />

		<Acesso v-if="loginStore.logado" title="Acesso" />
	</nav>
</template>

<script>
	import Dados from '../components/Dados'
	import Suporte from '../components/Suporte'
	import Acesso from '../components/Acesso'
	import { ref } from 'vue'
	import $ from 'jquery'
	import 'bootstrap/dist/css/bootstrap.min.css'
	import 'bootstrap'
	import { useLoginStore } from '../stores/LoginStore.js'
	import { useRouter } from 'vue-router'

	export default {
		name: 'navbar',
		components: {
			Dados,
			Suporte,
			Acesso,
		},
		setup() {
			let exibeTabelaAgendamento = ref(false)
			let active = ref(false)
			const router = useRouter()
			const loginStore = useLoginStore()
			let isOpen = false

			$(async function () {
				if (
					loginStore.token === '' ||
					loginStore.token === null ||
					loginStore.token === undefined
				) {
					//navigating
					router.push({
						path: '/efetuarLogin',
					})
				}
			})

			function buscarReqAgendadas() {
				const body = document.querySelector('body')
				this.active = !this.active
				this.active
					? body.classList.add('modal-open')
					: body.classList.remove('modal-open')

				$(async function () {
					let data = loginStore.requisicoesConcluidas

					$('#tb_agendamentos_concluidos').DataTable({
						data: data,
						language: {
							emptyTable: data.Info,
						},
						scrollY: '400px',
						scrollX: true,
						scrollCollapse: true,
						columnDefs: [
							{
								width: '20%',
								defaultContent: '-',
								targets: '_all',
								className: 'dt-body-left',
							},
						],
						fixedColumns: true,
						destroy: true,
						paging: true,
						searching: true,
						columns: [
							{
								data: 'id',
								title: '#Req',
							},
							{
								data: 'dtCreate',
								title: 'Data de Criação',
							},
							{
								data: 'dtLastUpdate',
								title: 'Data de Atualização',
							},
							{
								data: 'link',
								render: function (data, type) {
									if (type === 'display') {
										data = '<a href=' + data + '>download</a>'
									}
									return data
								},
								title: 'Link do Arquivo',
							},
							{
								data: 'parameters',
								title: 'Parâmetros',
							},
							/*
                    {
                        data: 'service.path',
                        title: 'Path'
                    },					
                    {
                        data: 'service.system.name',
                        title: 'Sistema'
                    }
                    */
							{
								data: 'status.description',
								title: 'Status',
							},
						],
					})
				})

				exibeTabelaAgendamento.value =
					loginStore.requisicoesConcluidas.length > 0 ? true : false
			}

			return {
				loginStore,
				router,
				exibeTabelaAgendamento,
				buscarReqAgendadas,
				isOpen,
				active,
			}
		},
	}
</script>
<style>
	nav {
		width: 100vw;
		background-color: #3276b1;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	nav .menu-item {
		color: #fff;
		padding: 25px 40px;
		position: relative;
		text-align: center;
		border-bottom: 5px solid transparent;
		display: flex;
		transition: 0.4s;
	}

	nav .menu-item.active,
	nav .menu-item:hover {
		background-color: #093f6e;
		border-bottom-color: #3276b1;
	}

	nav .menu-item a {
		color: inherit;
		text-decoration: none;
	}

	header {
		width: 100vw;
		padding: 25px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		background-color: rgb(70, 163, 192);
		padding: 20px 20px;
	}

	header .header-img {
		width: 100vw;
	}

	/** 
#logo {
    width: 175px;
    height: 139px;
}
*/
</style>
