<template>
	<div class="container">
		<h3 class="p-3 text-center">Meus Agendamentos de Dados</h3>
		<div class="gap-20"></div>
		<!--Este div serve para consultar os agendamentos de requisições-->
		<div class="panel panel-primary pedui-panel">
			<div class="panel-body">
				<div class="row pedui-search-filter">
					<div class="row">
						<div class="col-4">
							<label for="selectStatusreq">Status da Requisição</label>
							<select
								class="form-select"
								id="selectStatusreq"
								v-model="statusreq"
							>
								<option value="">Selecione o status da requisição</option>
								<option
									v-for="(statusreq, index) in statusreqs"
									:value="statusreq"
									:key="index"
								>
									{{ statusreq }}
								</option>
							</select>
						</div>
					</div>

					<div class="row" style="padding-top: 15px">
						<div class="col-lg-4" style="text-align: left">
							<button
								@click.prevent="buscarReqAgendadas"
								type="submit"
								class="btn btn-sm btn-info edit-button pull-right"
							>
								<span
									class="glyphicon glyphicon-search"
									aria-hidden="true"
								></span>
								Consultar
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="gap-40"></div>

		<table
			class="table table-hover table-responsive-sm"
			style="width: 90%"
			id="tb_agendamentos"
		></table>
	</div>
</template>

<script>
	import { ref } from 'vue'
	import 'bootstrap/dist/css/bootstrap.min.css'
	import 'jquery/dist/jquery.min.js'
	import $ from 'jquery'
	import SwsApplication from '@/sws.application.js'
	//Datatable Modules
	import 'datatables.net-dt/js/dataTables.dataTables'
	import 'datatables.net-dt/css/jquery.dataTables.min.css'

	export default {
		name: 'AgendaReq',
		setup() {
			const statusreqs = ref(['PENDENTE', 'REJEITADA', 'EXPIRADA', 'CONCLUIDA'])
			let statusreq = ref('')

			function buscarReqAgendadas() {
				let statusreqparam = this.statusreq

				$(async function () {
					let data = await SwsApplication.agendamentos(statusreqparam)

					$('#tb_agendamentos').DataTable({
						data: data,
						language: {
							emptyTable:
								'Nenhum resultado foi encontrado para essa requisição!',
						},
						destroy: true,
						paging: true,
						searching: true,
						columnDefs: [
							{
								targets: '_all',
								defaultContent: '-',
								className: 'dt-body-left',
							},
						],
						columns: [
							{
								data: 'id',
								title: '#Req',
							},
							{
								data: 'dtCreate',
								title: 'Data de Criação',
							},
							{
								data: 'dtLastUpdate',
								title: 'Data de Atualização',
							},
							{
								data: 'link',
								title: 'Link do Arquivo',
							},
							{
								data: 'parameters',
								title: 'Parâmetros da Requisição',
							},
							{
								data: 'status.description',
								title: 'Status',
							},
						],
					})
				})
			}

			return {
				statusreq,
				statusreqs,
				buscarReqAgendadas,
			}
		},
		mounted() {
			console.log('Sucesso')
		},
		beforeUnmount() {
			console.log('unmount')

			if ($.fn.DataTable.isDataTable('#tb_agendamentos')) {
				if ($('#tb_agendamentos').DataTable() != null) {
					var tableId = '#tb_agendamentos'

					$('#tb_agendamentos').DataTable().clear()
					$('#tb_agendamentos').DataTable().destroy()
					//window.removeEventListener('resize', this.someMethod)

					//2nd empty html
					$(tableId + ' tbody').empty()
					$(tableId + ' thead').empty()
					$('#tb_agendamentos').empty()
				}
			}
		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	h3 {
		margin: 40px 0 0;
		color: #000000;
	}

	ul {
		list-style-type: none;
		padding: 0;
	}

	li {
		display: inline-block;
		margin: 0 10px;
	}

	a {
		color: #42b983;
	}

	.search-result {
		float: left;
		margin-top: 45px;
		text-align: left;
	}

	.search-result-error {
		float: left;
		margin-top: 45px;
		text-align: center;
	}

	/*Bootstrap: Mudança da cor dos botões*/
	.btn-primary {
		background-color: #000000;
		border-color: #000000;
	}

	/*Bootstrap: Mudança da cor dos botões*/
	.btn-info {
		background-color: #3276b1;
		border-color: #3276b1;
		color: #ffffff;
	}

	/*Evita a geração de scroll horizontal*/
	.row {
		margin-right: 0px;
		margin-left: 0px;
	}

	.row-margin-top {
		margin-top: 10px;
	}

	.gap-40 {
		width: 100%;
		height: 40px;
	}

	.gap-30 {
		width: 100%;
		height: 30px;
	}

	.gap-20 {
		width: 100%;
		height: 20px;
	}

	.gap-10 {
		width: 100%;
		height: 10px;
	}
</style>
