import { defineStore } from 'pinia'
import axios from 'axios'
import { ref } from 'vue'
import UtilsApplication from '@/utils.application.js'
const urlTokens = '/SGAA/rest/controle-token/tokens'
const urlRoleServices = '/SGAA/rest/controle-usuario/servicos/usuario-nome'
import router from '../router/index'

const client = axios.create({
	baseURL: process.env.VUE_APP_SGAA_BASE_URL,
	headers: {
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
		'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
	},
})

const statusReqParam = ref('CONCLUIDA')

export const useLoginStore = defineStore('loginStore', {
	//state
	state: () => ({
		token: '',
		email: '',
		logado: false,
		usuarioAdm: false,
		tpUsuario: '',
		temRequisicaoConcluida: false,
		requisicoesConcluidas: [],
	}),

	//actions
	actions: {
		async fetchToken(credential) {
			const options = {
				url: urlTokens,
				method: 'POST',
				data: credential,
			}

			let response = await client(options).catch((error) => {
				console.log('Houve um erro ao chamar o ws!', error)
				return error.response
			})

			if (response?.status === 200) {
				this.token = response.data.token
				if (this.checkIsLogged()) {
					this.logado = true
					this.email = credential.email
				}
			}
			return response
		},
		async checkUsuarioAdm(username) {
			const options = {
				url: urlRoleServices,
				method: 'GET',
				params: {
					email: username,
				},
				headers: {
					token: this.token,
				},
			}

			let response = await client(options).catch((error) => {
				console.log('Houve um erro ao chamar o ws!', error)
				return error.response
			})

			if (response?.status === 200) {
				this.tpUsuario = response.data.roleId
				if (response.data.roleId === 1) {
					this.usuarioAdm = true
				}
			}
		},
		async checkRequisicaoConcluida() {
			this.requisicoesConcluidas = await UtilsApplication.agendamentos(
				statusReqParam.value,
			)

			if (this.requisicoesConcluidas.length > 0) {
				console.log('possui requisicoes concluidas')
				this.temRequisicaoConcluida = true
			}
		},
		checkIsLogged() {
			return (
				this.token !== '' && this.token !== null && this.token !== undefined
			)
		},
		logout() {
			this.token = ''
			this.email = ''
			this.usuarioAdm = false
			this.temRequisicaoConcluida = false
			this.requisicoesConcluidas = []
			this.logado = false
			;(this.tpUsuario = ''), localStorage.clear()

			//navigating
			router.push({
				path: '/efetuarLogin',
			})
		},
	},
})
