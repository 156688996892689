<template>
	<div class="container">
		<div class="gap-40"></div>
		<div class="row">
			<pre><label class="col-lg-8" style="float: left;">Erro: {{$route.params.msg}}</label></pre>
		</div>
		<div id="divButtonVoltar" class="form-group row">
			<div class="row">
				<div class="col-sm-3">
					<button
						id="buttonVoltar"
						@click.prevent="$router.back()"
						type="submit"
						class="btn btn-sm btn-info"
						style="float: right"
					>
						Voltar
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script></script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	h3 {
		margin: 40px 0 0;
		color: #000000;
	}

	ul {
		list-style-type: none;
		padding: 0;
	}

	li {
		display: inline-block;
		margin: 0 10px;
	}

	a {
		color: #42b983;
	}

	pre {
		white-space: pre-line;
	}

	/*Bootstrap: Mudança da cor dos botões*/
	.btn-primary {
		background-color: #3c8dbc;
		border-color: #3c8dbc;
	}
	/*Bootstrap: Mudança da cor dos botões*/
	.btn-info {
		background-color: #3276b1;
		border-color: #3276b1;
		color: #ffffff;
	}

	/*Evita a geração de scroll horizontal*/
	.row {
		margin-right: 0px;
		margin-left: 0px;
		margin-top: 15px;
	}

	.row-margin-top {
		margin-top: 15px;
	}

	.gap-40 {
		width: 100%;
		height: 40px;
	}

	.gap-30 {
		width: 100%;
		height: 30px;
	}

	.gap-20 {
		width: 100%;
		height: 20px;
	}

	.gap-10 {
		width: 100%;
		height: 10px;
	}
</style>
