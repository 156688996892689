import router from './router/index'
import axios from 'axios'
import axiosRetry from 'axios-retry'
//import { AES, enc } from 'crypto-js';
import { useLoginStore } from './stores/LoginStore'

const urlUsuarios = '/SGAA/rest/controle-usuario/usuarios'
const urlUsuarioPerfil = '/SGAA/rest/controle-usuario/usuario-perfil'
const urlPerfis = '/SGAA/rest/controle-perfil/perfis'
const urlTokens = '/SGAA/rest/controle-token/tokens'
const urlUsuarioSenha = '/SGAA/rest/controle-usuario/usuarios-senha'

axiosRetry(axios, {
	retries: 3,
})

class SgaaApplication {
	//static token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIiLCJwYXNzd29yZCI6InpZaUI3TTV4Uk5Jb1pYTzRBVzBCd2ZBVkRXd3RFUEJ2T1Q1ekgwWWlXTm89IiwiaXNzIjoiYnIuZ292LmNlbWFkZW4iLCJleHAiOjE2NzExNDI0OTQsImlhdCI6MTY3MTEyODA5NCwianRpIjoiNzk1NmFkYzktOTMwNi00MTRhLTlhY2YtMTRlZmUzNTAzYzcwIiwidXNlcm5hbWUiOiIxUnJyc2FvaEVaQjhpYkJJMFNjeHBEaktKNDFxUlVzVnpDRE1vcyt6bXlFPSJ9.8QfWFfhk026Qh_4UL-qbhYKYk4NsqVAzm99GZRq3aR8';

	static client = axios.create({
		baseURL: process.env.VUE_APP_SGAA_BASE_URL,
	})

	static async execute(options, hasTokenInHeader, hasNextPage) {
		const loginStore = useLoginStore()

		if (hasTokenInHeader) {
			options.headers = {
				//'token': localStorage.getItem('token') == (null || undefined) ? null : AES.decrypt(localStorage.getItem('token'), 'MYKEY4DEMO').toString(enc.Utf8)
				token:
					loginStore.token == ('' || null || undefined)
						? null
						: loginStore.token,
			}
		}

		let response = await this.client(options).catch((error) => {
			console.log('Houve um erro ao chamar o ws!', error)
			return error.response
		})

		if (!hasNextPage) return response.data

		if (response.status == 200) {
			if (!hasTokenInHeader) {
				if (options.url == urlTokens) {
					// Pagina de login
					//navigating
					router.push({
						name: 'RegrasUtilizacao',
						params: {
							msg: response.data,
						},
					})
				} else if (
					options.url == urlUsuarios ||
					options.url == urlUsuarioSenha
				) {
					// Página de cadastro usuario comum
					return response
				}
			} else if (options.url == urlUsuarioPerfil) {
				//navigating
				router.push({
					name: 'PaginaSucesso',
					params: {
						msg: 'Atualização de perfil realizada com sucesso !',
					},
				})
			} else if (options.url == urlUsuarios) {
				//Página de cadastro usuario adm
				//navigating
				router.push({
					name: 'PaginaSucesso',
					params: {
						msg: 'Usuário ' + options.data.email + 'cadastrado com sucesso !',
					},
				})
			} else {
				//navigating
				router.push({
					name: 'PaginaSucesso',
					params: {
						msg: response.data,
					},
				})
			}
		} else if (response.status == 400 || response.status == 401) {
			//navigating
			router.push({
				name: 'PaginaErro',
				params: {
					msg: response.data[0].Alerta,
				},
			})
		} else if (response.status == 404) {
			//navigating
			router.push({
				name: 'PaginaErro',
				params: {
					msg: 'O serviço está inacessível! Aguarde alguns minutos e tente novamente.',
				},
			})
		} else {
			//navigating
			router.push({
				name: 'PaginaErro',
				params: {
					msg: 'Houve um erro ao acessar esse serviço! Aguarde alguns minutos e tente novamente.',
				},
			})
		}
	}

	static async usuarios(email, passwd, perfil) {
		const options = {
			url: urlUsuarios,
			method: 'POST',
			data: {
				email: email,
				password: passwd,
				role: {
					id: perfil,
				},
			},
		}
		return SgaaApplication.execute(options, false, true)
	}

	static async usuarioCredencial(email, passwd) {
		const options = {
			url: urlUsuarios,
			method: 'GET',
			params: {
				email: email,
				password: passwd,
			},
		}
		return SgaaApplication.execute(options, true, true)
	}

	static async usuarioSenha(credential) {
		const options = {
			url: urlUsuarioSenha,
			method: 'PUT',
			data: credential,
		}
		return SgaaApplication.execute(options, false, true)
	}

	static async usuarioPerfil(email, perfil) {
		const options = {
			url: urlUsuarioPerfil,
			method: 'PUT',
			data: {
				email: email,
				roleuser: {
					id: perfil,
				},
			},
		}
		return SgaaApplication.execute(options, true, true)
	}

	static async perfis() {
		const options = {
			url: urlPerfis,
			method: 'GET',
		}
		return SgaaApplication.execute(options, true, false)
	}

	static async efetuarLogin(params) {
		const options = {
			url: urlTokens,
			method: 'POST',
			data: params,
		}
		return SgaaApplication.execute(options, false, true)
	}
}

export default SgaaApplication
