<template>
	<!-- Main Content -->
	<div class="container">
		<h3 class="p-3 text-center">Consulta de Acumulados</h3>
		<!-- Este div serve para consulta de dados acumulados -->
		<form id="formMatrix">
			<div class="form-group">
				<div class="row">
					<div id="divEstado" class="col-3">
						<label for="selectUF">UF (*)</label>
						<select
							class="form-select"
							id="selectUF"
							v-model="uf"
							v-on:change="carregaCbMunicipios"
						>
							<option value="">Selecione o estado</option>
							<option v-for="(uf, index) in ufs" :value="uf" :key="index">
								{{ uf }}
							</option>
						</select>
					</div>
					<div id="divCity" class="col-4">
						<label for="selectCity">Município (*)</label>
						<select
							class="form-select"
							id="selectCity"
							v-model="municipio"
							v-on:change="carregaCbEstacoes"
						>
							<option value="">Selecione o município</option>
							<option
								v-for="(municipio, index) in municipios"
								:value="municipio.codibge"
								:key="index"
							>
								{{ municipio.cidade }}
							</option>
						</select>
					</div>
					<div id="divStation" class="col-5">
						<label for="selectStation">Estação</label>
						<select class="form-select" id="selectStation" v-model="estacao">
							<option value="">Selecione a estação</option>
							<option
								v-for="(estacao, index) in estacoes"
								:value="estacao"
								:key="index"
							>
								{{ estacao.nome === '' ? ' - ' : estacao.nome + ' - ' }}
								{{ estacao.codestacao }}
							</option>
						</select>
					</div>
				</div>
			</div>

			<div class="gap-30"></div>

			<div id="divData" class="form-group">
				<div class="row">
					<div id="divDtAtual" class="col-2">
						<label for="radioMomentoAtual">
							<input
								type="radio"
								name="optradio2"
								id="radioMomentoAtual"
								value="2"
								:checked="checkedValueDt == '2'"
								v-model="checkedValueDt"
								v-on:click="execTaskDtAtual"
							/>
							<span style="margin-left: 5px; margin-right: 5px"
								>Momento Atual</span
							>
						</label>
					</div>
					<div id="divDtHistorica" class="col-7">
						<div class="row">
							<div id="divDtHistoricaLabel" class="col-3">
								<label for="radioDtHistorica">
									<input
										type="radio"
										name="optradio1"
										id="radioDtHistorica"
										value="1"
										:checked="checkedValueDt == '1'"
										v-model="checkedValueDt"
										v-on:click="execTaskDtHist"
									/>
									<span style="margin-left: 2px; margin-rigth: 2px"
										>Data Histórica (UTC)</span
									>
								</label>
							</div>
							<div id="divDtHistorica" v-if="checkRadioDtHist" class="col-4">
								<el-date-picker
									v-model="dtHistorica"
									type="datetime"
									placeholder="Seleciona uma data e hora"
								/>
								<!--
								<VueDatePicker v-model="dtHistorica" :auto-position="false" text-input
									:text-input-options="textInputOptions" />
								-->
							</div>
						</div>
					</div>

					<!--
			<span style="margin-left: 5px;margin-rigth: 5px;">
				<input type="datetime-local" id="inputDtHist" v-model="dtHistorica">
			</span>
			-->
				</div>
			</div>

			<div class="gap-20"></div>

			<div id="divButton" class="form-group">
				<div class="row">
					<div class="col-1">
						<button
							id="buttonMatrix"
							@click.prevent="buscarAcumulados"
							type="submit"
							class="btn btn-sm btn-info"
						>
							Consultar
						</button>
					</div>
					<div class="col-2">
						<pre id="loadingAnimation"></pre>
					</div>
				</div>
			</div>

			<div class="gap-10"></div>

			<div v-if="error" id="divMsgError">
				<p>{{ error }}</p>
			</div>

			<div class="gap-10"></div>

			<div v-if="exibeDataHoraAtual" id="divDataHoraAtual">
				<p>Data hora de Referência: {{ dataHoraAtual }}</p>
			</div>

			<div class="gap-10"></div>
		</form>
	</div>

	<div class="gap-40"></div>

	<table
		class="table table-hover table-responsive-sm"
		style="width: 90%"
		id="tb_acumulados"
	></table>
</template>

<script>
	import { ref } from 'vue'
	import 'bootstrap/dist/css/bootstrap.min.css'
	import 'jquery/dist/jquery.min.js'
	//Datatables modules
	import 'datatables.net-buttons-dt/css/buttons.dataTables.min.css';
	import 'datatables.net-buttons/js/dataTables.buttons.min.js';
	import 'datatables.net-buttons/js/buttons.html5.min.js';
	import "datatables.net";
	import "datatables.net-dt";
	import "datatables.net-dt/css/jquery.dataTables.css";

	import $ from 'jquery'

	import SwsApplication from '@/sws.application.js'


	import {
		startLoadingAnimation,
		stopLoadingAnimation,
	} from '@/load.animation.js'

	export default {
		name: 'AcumuladosReq',
		//	components: { VueDatePicker },
		components: {},
		setup() {
			let uf = ref('')
			let ufs = ref([
				'AC',
				'AL',
				'AM',
				'AP',
				'BA',
				'CE',
				'DF',
				'ES',
				'GO',
				'MA',
				'MG',
				'MS',
				'MT',
				'PA',
				'PB',
				'PE',
				'PI',
				'PR',
				'RJ',
				'RN',
				'RO',
				'RR',
				'RS',
				'SC',
				'SE',
				'SP',
				'TO',
			])
			let municipio = ref('')
			let municipios = ref([])
			let estacao = ref('')
			let estacoes = ref([])
			let dtHistorica = ref('')
			let checkedValueDt = ref('2')
			let checkRadioDtHist = false
			const textInputOptions = ref({ format: 'dd/MM/yyyy HH:mm' })
			const error = ref('')
			const dataHoraAtual = ref(new Date().toLocaleString('pt-br'))
			const exibeDataHoraAtual = true

			function execTaskDtAtual() {
				this.dtHistorica = ref('')
				this.checkRadioDtHist = false
				//ver como recuperar datahora atual formatada
				this.dataHoraAtual = new Date().toLocaleString('pt-br')
				this.exibeDataHoraAtual = true
			}

			function execTaskDtHist() {
				this.checkRadioDtHist = true
				//ver como recuperar datahora atual formatada
				this.dataHoraAtual = ref('')
				this.exibeDataHoraAtual = false
			}

			function carregaCbMunicipios() {
				if (this.uf) {
					//Reseta msg de erro
					this.error = ref('')
					//Reseta o combo de estações
					this.estacoes = ref([])
					//Reseta o valor inicial do combo de Estação
					this.estacao = ref('')
					// Carrega combo de municípios da UF selecionada
					SwsApplication.cidades(this.uf).then(
						(data) => (this.municipios = data)
					)
				} else {
					//Reseta o valor inicial do combo de Município
					this.municipio = ref('')
					//Reseta o combo de municípios
					this.municipios = ref([])
					//Reseta o valor inicial do combo de Estação
					this.estacao = ref('')
					//Reseta o combo de estações
					this.estacoes = ref([])
				}
			}

			function carregaCbEstacoes() {
				if (this.municipio) {
					//Reseta msg de erro
					this.error = ref('')
					// Carrega combo de estações do Município selecionado
					SwsApplication.estacoes(this.municipio).then(
						(data) => (this.estacoes = data)
					)
				} else {
					//Reseta msg de erro
					this.error = ref('')
					//Reseta o valor inicial do combo de Estação
					this.estacao = ref('')
					//Reseta o combo de estações
					this.estacoes = ref([])
				}
			}

			function convertDtToaaaaMMddHHmm(date) {
				let day = zeroPad(date.getDate(), 2)
				let month = zeroPad(date.getMonth() + 1, 2)
				let year = zeroPad(date.getFullYear(), 4)
				let hour = zeroPad(date.getHours(), 2)
				let minute = zeroPad(date.getMinutes(), 2)

				return year + month + day + hour + minute
			}

			function zeroPad(num, count) {
				let z = num + ''
				while (z.length < count) {
					z = '0' + z
				}
				return z
			}

			function buscarAcumulados() {
				if (!this.uf) {
					this.error = 'Por favor selecione uma UF!'
					return
				}

				if (!this.municipio) {
					this.error = 'Por favor selecione um município!'
					return
				}

				let params = {
					checkedValueDt: this.checkedValueDt,
					codibge: this.municipio,
					data: this.dtHistorica,
				}

				if (this.estacao.codestacao) {
					params.codestacao = this.estacao.codestacao
				}

				const animationInterval = startLoadingAnimation()

				$(async function () {
					let data = {}

					if (params.checkedValueDt == '1' && params.data) {
						let dataFmt = convertDtToaaaaMMddHHmm(new Date(params.data))
						data = await SwsApplication.acumuladosHistoricos(
							params.codestacao,
							params.codibge,
							dataFmt
						)
					} else if (params.checkedValueDt == '2') {
						data = await SwsApplication.acumuladosRecentes(
							params.codestacao,
							params.codibge
						)
					}

					if (data) {
						// Parar a animação de carregamento
						stopLoadingAnimation(animationInterval)
					}

					$('#tb_acumulados').DataTable({
						fixedHeader: {
							header: true,
							footer: true,
						},
						data: data,
						language: {
							emptyTable:
								'Nenhum resultado foi encontrado para essa requisição!',
						},
						destroy: true,
						paging: true,
						searching: true,
						processing: true,
						dom: 'Bfrtip',
						buttons: ['copy', 'csv', 'print'],
						columnDefs: [
							{
								targets: '_all',
								defaultContent: '-',
								className: 'dt-body-left',
							},
						],
						columns: [
							{
								data: 'codestacao',
								title: 'PCD (Pluv.)',
							},
							{
								data: 'acc1hr',
								title: 'mm/1h',
							},
							{
								data: 'acc3hr',
								title: 'mm/3h',
							},
							{
								data: 'acc6hr',
								title: 'mm/6h',
							},
							{
								data: 'acc12hr',
								title: 'mm/12h',
							},
							{
								data: 'acc24hr',
								title: 'mm/24h',
							},
							{
								data: 'acc48hr',
								title: 'mm/48h',
							},
							{
								data: 'acc72hr',
								title: 'mm/72h',
							},
							{
								data: 'acc96hr',
								title: 'mm/96h',
							},
							{
								data: 'acc120hr',
								title: 'mm/120h',
							},
						],
					})
				})
			}

			return {
				uf,
				ufs,
				municipio,
				municipios,
				estacao,
				estacoes,
				dtHistorica,
				dataHoraAtual,
				exibeDataHoraAtual,
				textInputOptions,
				checkedValueDt,
				checkRadioDtHist,
				execTaskDtAtual,
				execTaskDtHist,
				error,
				carregaCbMunicipios,
				carregaCbEstacoes,
				buscarAcumulados,
			}
		},
		mounted() {
			console.log('Sucesso')
		},
		beforeUnmount() {
			console.log('unmount')

			if ($.fn.DataTable.isDataTable('#tb_acumulados')) {
				if ($('#tb_acumulados').DataTable() != null) {
					var tableId = '#tb_acumulados'

					$('#tb_acumulados').DataTable().clear()
					$('#tb_acumulados').DataTable().destroy()
					//window.removeEventListener('resize', this.someMethod)

					//2nd empty html
					$(tableId + ' tbody').empty()
					$(tableId + ' thead').empty()
					$('#tb_acumulados').empty()
				}
			}
		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	h3 {
		margin: 40px 0 0;
		color: #000000;
	}

	ul {
		list-style-type: none;
		padding: 0;
	}

	li {
		display: inline-block;
		margin: 0 10px;
	}

	a {
		color: #42b983;
	}

	/* Estilo para a tag <pre> */
	pre {
		color: blue;
		/* Define a cor do texto para azul */
	}

	.search-result {
		float: left;
		margin-top: 45px;
		text-align: left;
	}

	.search-result-error {
		float: left;
		margin-top: 45px;
		text-align: center;
	}

	/*Bootstrap: Mudança da cor dos botões*/
	.btn-primary {
		background-color: #000000;
		border-color: #000000;
	}

	/*Bootstrap: Mudança da cor dos botões*/
	.btn-info {
		background-color: #3276b1;
		border-color: #3276b1;
		color: #ffffff;
	}

	/*Evita a geração de scroll horizontal*/
	.row {
		margin-right: 0px;
		margin-left: 0px;
	}

	.row-margin-top {
		margin-top: 10px;
	}

	.divData {
		float: left;
		padding-top: 2em;
		padding-bottom: 2em;
	}

	.divDtAtual {
		float: left;
		padding-right: 2em;
		padding-top: 2em;
		padding-bottom: 2em;
		border-radius: 0.5em;
	}

	.divDtHistoricaLabel {
		float: left;
		padding-right: 2em;
		padding-top: 2em;
		padding-bottom: 2em;
		border-radius: 0.5em;
	}

	.divDtHistorica {
		float: left;
		padding-right: 2em;
		padding-top: 2em;
		padding-bottom: 2em;
		border-radius: 0.5em;
	}

	.divObs {
		float: left;
		padding-right: 2em;
		padding-top: 2em;
		padding-bottom: 2em;
		border-radius: 0.5em;
	}

	table.dataTable thead th,
	table.dataTable tfoot th {
		text-align: left;
	}
	table.dataTable thead th,
	table.dataTable thead td {
		padding: 10px 18px 10px 5px;
		border-right: 1px solid #dddddd;
		text-align: left;
	}
	table.dataTable tbody th,
	table.dataTable tbody td {
		padding: 10px 18px 10px 5px;
		border-right: 1px solid #dddddd;
		text-align: left;
	}

	.gap-40 {
		width: 100%;
		height: 40px;
	}

	.gap-30 {
		width: 100%;
		height: 30px;
	}

	.gap-20 {
		width: 100%;
		height: 20px;
	}

	.gap-10 {
		width: 100%;
		height: 10px;
	}

	#divDataHoraAtual {
		float: left;
		text-align: left;
		color: #353434;
	}

	#divMsgError {
		float: left;
		text-align: left;
		color: #f30606;
	}

	.alert-info {
		background-image: -webkit-linear-gradient(top, #d9edf7 0, #b9def0 100%);
		background-image: -o-linear-gradient(top, #d9edf7 0, #b9def0 100%);
		background-image: -webkit-gradient(
			linear,
			left top,
			left bottom,
			from(#d9edf7),
			to(#b9def0)
		);
		background-image: linear-gradient(to bottom, #d9edf7 0, #b9def0 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9edf7', endColorstr='#ffb9def0', GradientType=0);
		background-repeat: repeat-x;
		border-color: #9acfea;
	}
	.alert {
		/* text-shadow: 0 1px 0 rgba(255,255,255,.2); */
		-webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25),
			0 1px 2px rgba(0, 0, 0, 0.05);
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25),
			0 1px 2px rgba(0, 0, 0, 0.05);
	}
	.alert-info {
		color: #31708f;
		background-color: #d9edf7;
		border-color: #bce8f1;
	}
	.alert {
		padding: 15px;
		margin-bottom: 20px;
		border: 1px solid transparent;
		border-radius: 4px;
	}
</style>
