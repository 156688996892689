<template>
	<!-- Main Content -->
	<div class="container">
		<h3 class="p-3 text-center">Agendar Dados Históricos</h3>
		<!-- Este div serve para o agendamento de dados históricos -->
		<form id="formMatrix">
			<div class="form-group">
				<div class="row">
					<div id="divEstado" class="col-3">
						<label for="selectUF">UF</label>
						<select
							class="form-select"
							id="selectUF"
							v-model="uf"
							v-on:change="carregaCbMunicipios"
						>
							<option value="">Selecione o estado</option>
							<option v-for="(uf, index) in ufs" :value="uf" :key="index">
								{{ uf }}
							</option>
						</select>
					</div>

					<div id="divCity" class="col-4">
						<label for="selectCity">Município</label>
						<select
							class="form-select"
							id="selectCity"
							v-model="municipio"
							v-on:change="carregaCbEstacoes"
						>
							<option value="">Selecione o município</option>
							<option
								v-for="(municipio, index) in municipios"
								:value="municipio.codibge"
								:key="index"
							>
								{{ municipio.cidade }}
							</option>
						</select>
					</div>
				</div>

				<div class="gap-40"></div>

				<div class="row">
					<div id="divTpEstacao" class="col-3">
						<label for="selectTpEstacao">Tipo de Estação</label>
						<select
							class="form-select"
							id="selectTpEstacao"
							v-model="tpEstacao"
							v-on:click.once="carregaCbTpEstacoes"
							v-on:change="filtraCbEstacoesPorTipo"
						>
							<option value="">Selecione o tipo de estação</option>
							<option
								v-for="(tpEstacao, index) in tpEstacoes"
								:value="tpEstacao.tipoestacao"
								:key="index"
							>
								{{ tpEstacao.tipoestacaodescricao }}
							</option>
						</select>
					</div>

					<div id="divStation" class="col-5">
						<label for="selectStation">Estação</label>
						<select class="form-select" id="selectStation" v-model="estacao">
							<option value="">Selecione a estação</option>
							<option
								v-for="(estacao, index) in estacoes"
								:value="estacao"
								:key="index"
							>
								{{ estacao.nome === '' ? ' - ' : estacao.nome + ' - ' }}
								{{ estacao.codestacao }}
							</option>
						</select>
					</div>
				</div>

				<div class="gap-40"></div>

				<div class="row">
					<div id="divDtHistorica" class="col-4">
						<label for="inputDtHist">Selecione o intervalo:</label>
						<el-date-picker
							v-model="dtHist"
							type="datetimerange"
							:shortcuts="shortcuts"
							range-separator="Até"
							start-placeholder="Data Início"
							end-placeholder="Data Fim"
							@closed="resetErrorMsg"
						/>
						<!--
						<VueDatePicker v-model="dtHist" range multi-calendars multi-calendars-solo @closed="resetErrorMsg" />
						-->
					</div>
				</div>
				<!--
				<div class="gap-40"></div>

				<div class="row">

					<div id="divDtHistoricaInicio" class="col-4">
						<label for="inputDtHistInicio">Data Início (*)</label>
						<input type="datetime-local" id="inputDtHistInicio" v-model="dtHistoricaInicio">
					</div>
					<div id="divDtHistoricaFim" class="col-4">
						<label for="inputDtHistFim">Data Fim (*)</label>
						<input type="datetime-local" id="inputDtHistFim" v-model="dtHistoricaFim">
					</div>
				</div>
				-->

				<div class="gap-40"></div>

				<div class="row">
					<div id="divFmtEntrega" class="col-4">
						<label for="selectFmtEntrega">Formato</label>
						<select
							class="form-select"
							id="selectFmtEntrega"
							v-model="formatoArq"
						>
							<option value="">Selecione o Formato</option>
							<option
								v-for="(formatoArq, index) in formatos"
								:value="formatoArq.nome"
								:key="index"
								:selected="formatoArq === 'JSON'"
							>
								{{ formatoArq.nome }}
							</option>
						</select>
					</div>
				</div>
			</div>

			<div class="gap-40"></div>

			<div id="divButton" class="form-group">
				<div class="row">
					<div class="col-2">
						<button
							id="btnAgendarConsultaDadosHistoricos"
							@click.prevent="agendarConsultaDadosHistoricos"
							type="submit"
							class="btn btn-sm btn-info"
						>
							Agendar
						</button>
					</div>
				</div>
			</div>

			<div class="gap-10"></div>

			<div v-if="error" id="divMsgError">
				<p>{{ error }}</p>
			</div>
		</form>
	</div>
</template>

<script>
	import { ref } from 'vue'
	import 'bootstrap/dist/css/bootstrap.min.css'
	import 'jquery/dist/jquery.min.js'
	import $ from 'jquery'
	import SwsApplication from '@/sws.application.js'
	//import VueDatePicker from '@vuepic/vue-datepicker';
	//import '@vuepic/vue-datepicker/dist/main.css'

	export default {
		name: 'ConsultaDadosHistoricos',
		components: {},
		setup() {
			let uf = ref('')
			let ufs = ref([
				'AC',
				'AL',
				'AM',
				'AP',
				'BA',
				'CE',
				'DF',
				'ES',
				'GO',
				'MA',
				'MG',
				'MS',
				'MT',
				'PA',
				'PB',
				'PE',
				'PI',
				'PR',
				'RJ',
				'RN',
				'RO',
				'RR',
				'RS',
				'SC',
				'SE',
				'SP',
				'TO',
				'Todos',
			])
			let municipio = ref('')
			let municipios = ref([])
			let estacao = ref('')
			let estacoes = ref([])
			let todasEstacoesMunicipio = ref([])
			let tpEstacoes = ref([])
			let tpEstacao = ref('')
			let formatos = ref([
				{
					id: 1,
					nome: 'CSV',
				},
				{
					id: 2,
					nome: 'JSON',
				},
				{
					id: 3,
					nome: 'XML',
				},
				{
					id: 4,
					nome: 'CUSTOMIZADO',
				},
			])
			let formatoArq = ref('')
			let dtHist = ref('')
			const error = ref('')

			const resetErrorMsg = () => {
				this.error = ref('')
			}

			function convertDtToaaaaMMddHHmm(date) {
				let day = zeroPad(date.getDate(), 2)
				let month = zeroPad(date.getMonth() + 1, 2)
				let year = zeroPad(date.getFullYear(), 4)
				let hour = zeroPad(date.getHours(), 2)
				let minute = zeroPad(date.getMinutes(), 2)

				return year + month + day + hour + minute
			}

			function zeroPad(num, count) {
				let z = num + ''
				while (z.length < count) {
					z = '0' + z
				}
				return z
			}

			function carregaCbMunicipios() {
				if (this.uf && this.uf != 'Todos') {
					//Reseta msg de erro
					this.error = ref('')
					//Reseta o combo de estações
					this.estacoes = ref([])
					//Reseta o valor inicial do combo de Estação
					this.estacao = ref('')
					//Reseta o valor inicial do combo de Tipo de Estação
					this.tpEstacao = ref('')
					// Carrega combo de municípios da UF selecionada
					SwsApplication.cidades(this.uf).then(
						(data) => (this.municipios = data)
					)
				} else {
					//Reseta o valor inicial do combo de Município
					this.municipio = ref('')
					//Reseta o combo de municípios
					this.municipios = ref([])
					//Reseta o valor inicial do combo de Estação
					this.estacao = ref('')
					//Reseta o combo de estações
					this.estacoes = ref([])
					//Reseta o valor inicial do combo de Tipo de Estação
					this.tpEstacao = ref('')
				}
			}

			function carregaCbEstacoes() {
				if (this.municipio) {
					//Reseta msg de erro
					this.error = ref('')
					// Carrega combo de estacoes para o municípios selecionado
					SwsApplication.estacoes(this.municipio).then((data) => {
						this.estacoes = data
						this.todasEstacoesMunicipio = this.estacoes
					})
				} else {
					//Reseta o valor inicial do combo de Estação
					this.estacao = ref('')
					//Reseta o combo de estações
					this.estacoes = ref([])
					//Reseta o valor inicial do combo de Tipo de Estação
					this.tpEstacao = ref('')
				}
			}

			function carregaCbTpEstacoes() {
				SwsApplication.pcdsTipoEstacaoSensores().then(
					(data) => (this.tpEstacoes = data)
				)
			}

			function filtraCbEstacoesPorTipo() {
				this.estacoes = this.todasEstacoesMunicipio

				if (this.tpEstacao != 0) {
					//loop em this.estacoes para filtrar por tipo de estacao
					let estacoesFiltered = this.estacoes.filter(
						(estacao) => estacao.id_tipoestacao == this.tpEstacao
					)
					this.estacoes = estacoesFiltered
				}
			}

			function agendarConsultaDadosHistoricos() {
				let params = {}
				const rede = 11
				params.rede = rede

				if (!this.uf) {
					this.error = 'Por favor selecione uma UF!'
					return
				}

				if (!this.dtHist) {
					this.error = 'Por favor selecione uma data inicial e uma data final!'
					return
				}

				if (this.uf && this.dtHist) {
					if (this.uf != 'Todos') {
						params.uf = this.uf
					}
					params.dtInicio = convertDtToaaaaMMddHHmm(new Date(this.dtHist[0]))
					params.dtFim = convertDtToaaaaMMddHHmm(new Date(this.dtHist[1]))

					//params.dtInicio = convertDtToaaaaMMddHHmm(new Date(this.dtHistoricaInicio));
					//params.dtFim = convertDtToaaaaMMddHHmm(new Date(this.dtHistoricaFim));
				} else return
				if (this.municipio) {
					params.codibge = this.municipio
				}
				if (this.tpEstacao) {
					params.tipoestacao = this.tpEstacao
				}
				if (this.estacao.codestacao) {
					params.codestacao = this.estacao.codestacao
				}

				if (this.formatoArq) {
					params.file = this.formatoArq
				}

				$(async function () {
					await SwsApplication.agendarConsultaDadosHistoricos(
						params.dtInicio,
						params.dtFim,
						params.rede,
						params.codestacao,
						params.uf,
						params.codibge,
						params.tipoestacao,
						params.file
					)
				})
			}

			return {
				uf,
				ufs,
				municipio,
				municipios,
				estacao,
				estacoes,
				todasEstacoesMunicipio,
				tpEstacao,
				tpEstacoes,
				formatos,
				formatoArq,
				dtHist,
				error,
				carregaCbMunicipios,
				carregaCbEstacoes,
				carregaCbTpEstacoes,
				filtraCbEstacoesPorTipo,
				agendarConsultaDadosHistoricos,
				resetErrorMsg,
			}
		},
		mounted() {
			console.log('Sucesso')
		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	h3 {
		margin: 40px 0 0;
		color: #000000;
	}

	ul {
		list-style-type: none;
		padding: 0;
	}

	li {
		display: inline-block;
		margin: 0 10px;
	}

	a {
		color: #42b983;
	}

	.search-result {
		float: left;
		margin-top: 45px;
		text-align: left;
	}

	.search-result-error {
		float: left;
		margin-top: 45px;
		text-align: center;
	}

	/*Bootstrap: Mudança da cor dos botões*/
	.btn-primary {
		background-color: #000000;
		border-color: #000000;
	}

	/*Bootstrap: Mudança da cor dos botões*/
	.btn-info {
		background-color: #3276b1;
		border-color: #3276b1;
		color: #ffffff;
	}

	/*Evita a geração de scroll horizontal*/
	.row {
		margin-right: 0px;
		margin-left: 0px;
	}

	.row-margin-top {
		margin-top: 10px;
	}

	.divData {
		float: left;
		padding-top: 2em;
		padding-bottom: 2em;
	}

	.divDtAtual {
		float: left;
		padding-right: 2em;
		padding-top: 2em;
		padding-bottom: 2em;
		border-radius: 0.5em;
	}

	.divDtHistorica {
		float: left;
		padding-right: 2em;
		padding-top: 2em;
		padding-bottom: 2em;
		border-radius: 0.5em;
	}

	.divObs {
		float: left;
		padding-right: 2em;
		padding-top: 2em;
		padding-bottom: 2em;
		border-radius: 0.5em;
	}

	.gap-40 {
		width: 100%;
		height: 40px;
	}

	.gap-30 {
		width: 100%;
		height: 30px;
	}

	.gap-20 {
		width: 100%;
		height: 20px;
	}

	.gap-10 {
		width: 100%;
		height: 10px;
	}

	#divMsgError {
		text-align: left;
		color: #f30606;
	}
</style>
