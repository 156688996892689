<template>
	<table>
		<tbody>
			<tr>
				<td>
					<div id="appFaq">
						<div class="faq-container">
							<div class="gap-10"></div>
							<left><h1>Dúvidas Frequentes</h1></left>
							<div class="gap-60"></div>
							<ul class="faq-list">
								<h2>Dúvidas de Acesso</h2>
								<div class="gap-10"></div>
								<li v-for="(item, index) in faqItemsAcesso" :key="index">
									<div class="question">
										<i class="fas fa-circle-plus" aria-hidden="true"></i
										>{{ item.question }}
									</div>
									<div class="answer">{{ item.answer }}</div>
								</li>
								<div class="gap-30"></div>
								<h2>Dúvidas sobre Dados de Acumulados de Chuva</h2>
								<div class="gap-10"></div>
								<li v-for="(item, index) in faqItemsAcumulados" :key="index">
									<div class="question">
										<i class="fas fa-circle-plus" aria-hidden="true"></i
										>{{ item.question }}
									</div>
									<div class="answer">{{ item.answer }}</div>
								</li>
								<div class="gap-30"></div>
								<h2>Dúvida sobre Masssa de Dados Históricos</h2>
								<div class="gap-10"></div>
								<li
									v-for="(item, index) in faqItemsDadosHistoricos"
									:key="index"
								>
									<div class="question">
										<i class="fas fa-circle-plus" aria-hidden="true"></i
										>{{ item.question }}
									</div>
									<div class="answer">{{ item.answer }}</div>
								</li>
								<div class="gap-30"></div>
								<h2>Dúvida sobre Massa de Dados Recentes</h2>
								<div class="gap-10"></div>
								<li v-for="(item, index) in faqItemsDadosRecentes" :key="index">
									<div class="question">
										<i class="fas fa-circle-plus" aria-hidden="true"></i
										>{{ item.question }}
									</div>
									<div class="answer">{{ item.answer }}</div>
								</li>
								<div class="gap-30"></div>
								<h2>Dúvida Gerais</h2>
								<div class="gap-10"></div>
								<li v-for="(item, index) in faqItemsDadosGerais" :key="index">
									<div class="question">
										<i class="fas fa-circle-plus" aria-hidden="true"></i
										>{{ item.question }}
									</div>
									<div class="answer">{{ item.answer }}</div>
								</li>
							</ul>
						</div>
					</div>
				</td>
			</tr>
		</tbody>
	</table>
</template>

<script>
	// Importe os estilos CSS do Font Awesome
	import '@fortawesome/fontawesome-free/css/all.css'
	export default {
		data() {
			return {
				faqItemsAcesso: [
					{
						question: ' Como ter acesso à plataforma PED ?',
						answer:
							'Basta realizar o login na plataforma inserindo o seu usúario e senha, mas antes você deve se cadastrar na plataforma.',
					},
					{
						question: ' Como realizar o cadastro na plataforma ?',
						answer:
							'Para realizar o cadastro na plataforma você deverá clicar no botão "Criar uma Conta" que está presente na tela de login do sistema.',
					},
					{
						question: ' Esqueci minha senha como faço para recuperar  ?',
						answer:
							'Caso tenha esquecido ou perdido a sua senha de acesso à plataforma basta clicar no link "Esqueci minha senha" na tela inicial da plataforma (tela de login).',
					},
					{
						question: ' Posso alterar minha senha pelo sistema ?',
						answer:
							'Para realizar o cadastro na plataforma você deverá clicar no botão "Criar uma Conta" que está presente na tela de login do sistema.',
					},
					{
						question: ' Posso alterar minha senha pelo sistema ?',
						answer:
							'Sim, você pode alterar a sua senha após ter realizado o login na plataforma, mas para isso você deve clicar no item "Alterar minha senha" do submenu, logo após clicar no ícone de usuário, que fica situado no canto superior direito da tela, no menu principal da plataforma.',
					},
				],
				faqItemsAcumulados: [
					{
						question: ' É possível obter acumulados de chuva pela Plataforma ?',
						answer:
							'Sim. É possível obter os acumulados de 1h, 3h, 6h, 12h, 24h, 48h, 72h, 96h e 120h acessando o item "Acumulados" que está situado dentro do item "Dados" do menu principal da aplicação. Lembrando que os dados de acumulados serão exibidos por estação.',
					},
					{
						question:
							' É possível obter acumulados de chuva a partir da data atual ?',
						answer:
							'Sim. É possível obter os acumulados de 1h, 3h, 6h, 12h, 24h, 48h, 72h, 96h e 120h acessando o item "Acumulados" que está situado dentro do item "Obtenção de Dados" do menu principal da aplicação. Lembrando que os dados de acumulados serão exibidos por estação.',
					},
					{
						question:
							' É possível obter acumulados de chuva a partir de uma data no passado ?',
						answer:
							'Sim. É possível obter os acumulados históricos de 1h, 3h, 6h, 12h, 24h, 48h, 72h, 96h e 120h acessando o item "Acumulados" que está situado dentro do item "Obtenção de Dados" do menu principal da aplicação. Na tela que será apresentada basta preencher os campos obrigatórios de UF e município, clicar na opção "Data Histórica", incluir a data histórica desejada no campo de seleção de data e hora e clicar no no botão de pesquisa.',
					},
				],
				faqItemsDadosHistoricos: [
					{
						question:
							' Posso obter dados históricos de uma estação específica ?',
						answer:
							'Sim. Para obter os dados históricos para uma estação específica, basta selecionar a estação desejada, após acessar o item "Consultar Dados históricos" após clicar em "Obtenção de Dados" no menu principal da plataforma.',
					},
					{
						question:
							' Posso obter todos os dados históricos de uma UF ou município de uma vez ?',
						answer:
							'Sim. É possível obter os dados históricos de todas as estações de um município ou de todas as estações de um estado desde que você selecione um item da lista de municípios ou UF, deixando o campo "Estação" em branco.',
					},
					{
						question:
							' Como faço para baixar a base toda de dados dos Cemaden ?',
						answer:
							'Para baixar toda a base de dados do centro, basta clicar no botão "consultar" dentro da tela de busca por dados históricos (subitem "Consultar Dados Históricos" dentro do menu "Obtenção de Dados"), deixando os demais campos do formulário sem preenchimento.',
					},
					{
						question:
							' Como faço para visualizar se uma requisição de dados foi concluída ou não ?',
						answer:
							'Para verificar o status de uma requisição que você fez para a plataforma você precisa acessar a plataforma com seu usuário e senha e, uma vez logado, selecionar o item "Status de Agendamento de Dados Históricos" no menu principal da aplicação. Aparecerá uma listagem contendo os diversos estágios possíveis de uma requisição. Para verificar se a sua requisição foi finalizada selecione a opção de status "Concluída" e clique no botão "consultar". As outras opções possíveis são "Pendente", "Rejeitada" e "Expirada".',
					},
					{
						question:
							' Se eu não especificar um período (data de início e data fim) o sistema permite o agendamento de uma requisição de dados históricos ?',
						answer:
							'Não. O sistema não permite o agendamento de uma requisição de dados históricos sem que se insira uma data de início e de fim.',
					},
				],
				faqItemsDadosRecentes: [
					{
						question:
							' É possível visualizar ou recuperar os dados recentes de uma estação específica ?',
						answer:
							'Sim. Para recuperar os dados das últimas 3 horas das estações do centro você precisa realizar o login na plataforma e, uma vez logado, clicar no item "Obtenção de Dados" e em seguida selecionar o item "Dados Recentes" no submenu. Na tela que aparecerá em seguida você pode selecionar a estação desejada dentro de determinada localidade.',
					},
					{
						question:
							' É possível visualizar ou recuperar os dados recentes de todas as estações de uma UF ou município ?',
						answer:
							'Sim. É possível obter os dados recentes de todas as estações de um município ou de todas as estações de um estado de uma só vez, desde que você selecione um item da lista de municípios ou UF, deixando o campo "Estação" em branco.',
					},
				],
				faqItemsDadosGerais: [
					{
						question: ' Como faço para encerrar minha seção na plataforma ?',
						answer:
							'Basta clicar no ícone do usuário no canto superior direito da tela e em seguida selecionar o item "Sair" do submenu.',
					},
					{
						question:
							' Como faço para obter dados relaticos a uma estação ? Por ex: Data de instalação, local de instalação, etc.',
						answer:
							'Para visualizar os metadados de uma estação você precisa realizar o login na plataforma e, uma vez logado, clicar no item "Obtenção de Dados" e em seguida selecionar o item "Informações das Estações" no submenu. Na tela que aparecerá em seguida você pode selecionar a estação desejada dentro de determinada localidade.',
					},
					{
						question:
							' É possível saber quais os sensores presentes em cada estação ?',
						answer:
							'Sim, é possível saber quais os sensores presentas em cada tipo de estação. Para saber este tipo de informação você precisa acessar a plataforma com seu usuário e senha e, uma vez logado, selecionar o item "Lista de Sensores" no menu principal da aplicação. Aparecerá uma listagem contendo os diversos tipos de estações disponíveis na rede do Cemaden. Para verificar quais os sensores de cada tipo de estação basta selecionar o tipo de estação desejado e clicar no botão "consultar".',
					},
				],
			}
		},
	}
</script>

<style scoped>
	#appFaq {
		font-family: Arial, sans-serif;
		display: flex;
		justify-content: left;
		align-items: left;
		height: 100vh;
		margin: 125px;
	}

	.faq-container {
		width: 90%;
		max-width: 1200px;
		align-items: left;
		text-align: left;
	}

	.faq-list {
		list-style: none;
		padding: 0;
		align-items: left;
		text-align: left;
	}

	.question {
		font-weight: bold;
		cursor: pointer;
		margin-bottom: 5px;
		text-align: left;
	}

	.answer {
		margin-bottom: 20px;
		text-align: left;
		display: none;
		color: #f84545;
	}

	li:hover .answer {
		display: block;
	}
	.gap-60 {
		width: 100%;
		height: 60px;
	}
	.gap-40 {
		width: 100%;
		height: 40px;
	}
	.gap-30 {
		width: 100%;
		height: 30px;
	}
	.gap-20 {
		width: 100%;
		height: 20px;
	}
	.gap-10 {
		width: 100%;
		height: 10px;
	}
</style>
