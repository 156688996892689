<template>
	<!-- Main Content -->
	<div class="container">
		<h3 class="p-3 text-center">Confirme sua senha atual</h3>

		<form id="formMatrix">
			<div id="divSenhaAtual" class="form-group row">
				<div class="row">
					<label for="senhaAtual" class="col-sm-2 col-form-label"
						>Senha Atual (*)</label
					>
					<div class="col-sm-3">
						<input
							type="text"
							class="form-control"
							id="senhaAtual"
							v-model="senhaAtual"
							placeholder="Senha atual"
						/>
					</div>
				</div>
			</div>

			<div id="divNovaSenha" class="form-group row">
				<div class="row">
					<label for="senhaNova" class="col-sm-2 col-form-label"
						>Nova senha (*)</label
					>
					<div class="col-sm-3">
						<input
							type="text"
							class="form-control"
							id="senhaNova"
							v-model="novaSenha"
							placeholder="Nova senha"
						/>
					</div>
				</div>
			</div>

			<div id="divButton" class="form-group row">
				<div class="row">
					<div class="col-sm-2">
						<button
							id="buttonAlterarSenha"
							@click.prevent="alterarSenhaUsuario"
							type="submit"
							class="btn btn-sm btn-primary"
						>
							Alterar Senha
						</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
	import { ref, onMounted } from 'vue'
	//import SgaaApplication from '@/sgaa.application.js'
	import { Tooltip } from 'bootstrap'
	import { useLoginStore } from '../../stores/LoginStore'
	import $ from 'jquery'
	import SgaaApplication from '@/sgaa.application.js'
	import { useRouter } from 'vue-router'
	import { enviarEmailUsuarioAlteraSenha } from '@/mail.usermsg.js'
	import { validaSenha } from '@/utils.security.js'

	onMounted(() => {
		new Tooltip(document.body, {
			selector: "[data-bs-toggle='tooltip']",
		})
	})

	export default {
		name: 'AlterarSenhaUsuario',
		components: {},
		setup() {
			let token = ref('')
			let senhaAtual = ref('')
			let novaSenha = ref('')
			const router = useRouter()
			const loginStore = useLoginStore()

			async function alterarSenhaUsuario() {
				if (!senhaAtual.value || !novaSenha.value) {
					alert('Oops, você tem de preencher os campos solicitados!')
				} else {
					let credential = {
						email: loginStore.email,
						password: this.senhaAtual,
					}

					$(async function () {
						const responseToken = await loginStore.fetchToken(credential)
						if (responseToken.status === 200) {
							let credentialNovaSenha = {
								email: loginStore.email,
								password: novaSenha.value,
							}

							if (!validaSenha(credentialNovaSenha.password)) {
								//navigating
								router.push({
									name: 'PaginaErro',
									params: {
										msg: 'Erro: A senha informada não atende a pelo menos um dos critérios de segurança exigidos: 1) deve conter pelo menos um dígito; 2) deve conter pelo menos um caractere maiúsculo; 3) deve conter pelo menos um caractere minúsculo; 4) deve conter pelo menos um caractere especial; 5) não pode conter espaços em branco; 6) deve possuir pelo menos 8 caracteres.',
									},
								})
							} else {
								let response =
									await SgaaApplication.usuarioSenha(credentialNovaSenha)

								if (response.status === 200) {
									enviarEmailUsuarioAlteraSenha(credentialNovaSenha)
									//navigating
									router.push({
										name: 'PaginaSucesso',
										params: {
											msg:
												'Senha do usuário ' +
												credential.email +
												' alterada com sucesso !',
										},
									})
								}
							}
						}
					})
				}
			}

			return {
				token,
				senhaAtual,
				novaSenha,
				alterarSenhaUsuario,
			}
		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	h3 {
		margin: 40px 0 0;
		color: #000000;
	}

	ul {
		list-style-type: none;
		padding: 0;
	}

	li {
		display: inline-block;
		margin: 0 10px;
	}

	a {
		color: #42b983;
	}

	/*Bootstrap: Mudança da cor dos botões*/
	.btn-info {
		background-color: #3276b1;
		border-color: #3276b1;
		color: #ffffff;
	}

	/*Bootstrap: Mudança da cor dos botões*/
	.btn-success {
		background-color: #58b358;
		border-color: #58b358;
	}

	/*Bootstrap: Mudança da cor dos botões*/
	.btn-success {
		background-color: #58b358;
		border-color: #58b358;
	}

	/*Evita a geração de scroll horizontal*/
	.row {
		margin-right: 0px;
		margin-left: 0px;
		margin-top: 15px;
	}

	.row-margin-top {
		margin-top: 15px;
	}

	body {
		font-family: 'Avenir', Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	#App {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
	}

	#verified {
		color: white;
		background: green;
	}

	#error {
		color: white;
		background: red;
	}

	p {
		text-align: justify;
		margin: 10px 0;
		border: thin solid black;
		background-color: #f8f4f4;
	}

	.alert-info {
		background-image: -webkit-linear-gradient(top, #d9edf7 0, #b9def0 100%);
		background-image: -o-linear-gradient(top, #d9edf7 0, #b9def0 100%);
		background-image: -webkit-gradient(
			linear,
			left top,
			left bottom,
			from(#d9edf7),
			to(#b9def0)
		);
		background-image: linear-gradient(to bottom, #d9edf7 0, #b9def0 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9edf7', endColorstr='#ffb9def0', GradientType=0);
		background-repeat: repeat-x;
		border-color: #9acfea;
	}

	.alert {
		/* text-shadow: 0 1px 0 rgba(255,255,255,.2); */
		-webkit-box-shadow:
			inset 0 1px 0 rgba(255, 255, 255, 0.25),
			0 1px 2px rgba(0, 0, 0, 0.05);
		box-shadow:
			inset 0 1px 0 rgba(255, 255, 255, 0.25),
			0 1px 2px rgba(0, 0, 0, 0.05);
	}

	.alert-info {
		color: #31708f;
		background-color: #d9edf7;
		border-color: #bce8f1;
	}

	.alert {
		padding: 15px;
		margin-bottom: 20px;
		border: 1px solid transparent;
		border-radius: 4px;
	}
</style>

<style src="@vueform/multiselect/themes/default.css"></style>
