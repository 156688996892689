<template>
	<!-- Main Content -->
	<div class="container">
		<h3 class="p-3 text-center">Login do Usuário</h3>
		<!-- Este div serve para cadastro de um usuario -->
		<form id="formMatrix">
			<div id="divEmail" class="form-group row">
				<div class="row">
					<label for="email" class="col-sm-2 col-form-label" style="float: left"
						>E-mail</label
					>
					<div class="col-sm-4">
						<input
							type="text"
							class="form-control"
							id="email"
							v-model="email"
							placeholder="E-mail"
						/>
					</div>
				</div>
			</div>

			<div id="divPassword" class="form-group row">
				<div class="row">
					<label
						for="passwd"
						class="col-sm-2 col-form-label"
						style="float: left"
						>Senha</label
					>
					<div class="col-sm-4">
						<input
							type="password"
							class="form-control"
							id="passwd"
							v-model="passwd"
							placeholder="Senha"
						/>
					</div>
				</div>
			</div>

			<div id="divCaptcha" class="form-group row">
				<div class="row">
					<div class="col-sm-2"></div>
					<div class="col-sm-2">
						<!--
						<div v-if="verified" id="verified">
							<h1>Successfully Verified!</h1>
						</div>
						-->
						<div v-if="expired" id="expired">
							<h1>Challenge expired!</h1>
						</div>
						<div v-if="error" id="error">
							<h1>Error:</h1>
							<p>{{ error }}</p>
						</div>
						<!--
							<vue-hcaptcha sitekey="10000000-ffff-ffff-ffff-000000000001" @verify="onVerify" @expired="onExpire" @challenge-expired="onChallengeExpire" @error="onError"></vue-hcaptcha>
							-->
						<vue-hcaptcha
							sitekey="98ceb645-6a20-4b30-8d03-b709323fd8d7"
							@verify="onVerify"
							@expired="onExpire"
							@challenge-expired="onChallengeExpire"
							@error="onError"
						></vue-hcaptcha>
					</div>
				</div>
			</div>

			<div id="divButtonAvancar" class="form-group row">
				<div class="row">
					<div class="col-sm-3">
						<button
							id="buttonAvancar"
							@click.prevent="efetuarLogin"
							type="submit"
							class="btn btn-sm btn-info"
							style="float: right"
						>
							Avançar
						</button>
					</div>
					<div class="col-sm-3">
						<a href="#" @click="gerarNovaSenha" id="novaSenha"
							><span>Esqueci minha senha</span>
						</a>
					</div>
				</div>
			</div>
			<div id="divButtonCriarConta" class="form-group row">
				<div class="row">
					<div class="col-sm-3">
						<button
							id="buttonCriarConta"
							@click.prevent="$router.push({ name: 'CadastroUsuario' })"
							type="submit"
							class="btn btn-sm btn-success"
							style="float: right"
						>
							Criar uma Conta
						</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
	import { ref } from 'vue'
	import SgaaApplication from '@/sgaa.application.js'
	import $ from 'jquery'
	import VueHcaptcha from '@hcaptcha/vue3-hcaptcha'
	import { useRouter } from 'vue-router'
	import { AES } from 'crypto-js'
	//import { enc } from 'crypto-js';
	import { useLoginStore } from '../stores/LoginStore'
	import { enviarEmailUsuarioEsqueciSenha } from '@/mail.usermsg.js'
	import { gerarSenhaAlfanumerica } from '@/utils.security.js'

	export default {
		name: 'EfetuarLogin',
		components: { VueHcaptcha },
		setup() {
			const verified = ref(false)
			const expired = ref(false)
			const tokenCaptcha = ref('')
			const eKey = ref('')
			const error = ref('')

			let email = ref('')
			let passwd = ref('')
			let token = ref('')
			const router = useRouter()
			const loginStore = useLoginStore()

			function onVerify(tokenStr, ekey) {
				verified.value = true
				tokenCaptcha.value = tokenStr
				eKey.value = ekey
			}

			function onExpire() {
				verified.value = false
				tokenCaptcha.value = null
				eKey.value = null
				expired.value = true
			}

			function onChallengeExpire() {
				verified.value = false
				tokenCaptcha.value = null
				eKey.value = null
				expired.value = true
			}

			function onError(err) {
				tokenCaptcha.value = null
				eKey.value = null
				error.value = err
			}

			async function gerarNovaSenha() {
				// Gera nova senha de 8 dígito e concatena com string Aa@ para garantir o padrão de formacao de uma senha válida
				const novaSenha = gerarSenhaAlfanumerica(8).concat('1Aa@')

				const emailUsuario = prompt(
					'Por favor, digite o e-mail para envio da nova senha:'
				)
				if (!emailUsuario) {
					console.log('Acao cancelada pelo usuário')
				} else {
					let credential = {
						email: emailUsuario,
						password: novaSenha,
					}

					let response = await SgaaApplication.usuarioSenha(credential)

					if (response.status === 200) {
						console.log('Entrou status enviarEmailUsuarioSenha')
						enviarEmailUsuarioEsqueciSenha(credential)
						//navigating
						router.push({
							name: 'PaginaSucesso',
							params: {
								msg:
									'Uma nova senha foi gerada e enviada para o e-mail ' +
									credential.email +
									'. Verifique sua caixa de e-mail ou de spam, caso não tenha recebido.',
							},
						})
					}
				}
			}

			async function efetuarLogin() {
				let credential = {
					email: this.email,
					password: this.passwd,
				}

				$(async function () {
					if (
						!verified.value &&
						(tokenCaptcha.value == null || tokenCaptcha.value == '')
					) {
						alert('Oops, você tem de checar o Captcha')
					} else {
						const responseToken = await loginStore.fetchToken(credential)

						if (responseToken.status === 200) {
							this.token = responseToken.data.token

							// Criptografa o token e guarda na sessão
							let objToken = { token: this.token }
							const encryptedObject = AES.encrypt(
								JSON.stringify(objToken),
								'MYKEY4DEMO'
							)
							localStorage.setItem('token', encryptedObject.toString())
							// Lógica para recuperar o token e descriptografar
							//console.log(localStorage.getItem('token'));
							//let objDecript = AES.decrypt(localStorage.getItem('token'), 'MYKEY4DEMO').toString(enc.Utf8)
							//console.log(JSON.parse(objDecript).token);

							// Verifica se usuário é admin
							await loginStore.checkUsuarioAdm(email.value)

							// Verifica se o usuario possui alguma notificacao referente a requisicao concluida
							await loginStore.checkRequisicaoConcluida()

							//navigating
							router.push('/home')
						} else if (
							responseToken.status === 400 ||
							responseToken.status === 401
						) {
							//navigating
							router.push({
								name: 'PaginaErro',
								params: {
									msg: responseToken.data[0].Alerta,
								},
							})
						} else if (responseToken.status === 404) {
							//navigating
							router.push({
								name: 'PaginaErro',
								params: {
									msg: 'Serviço está inacessível.',
								},
							})
						} else {
							//navigating
							router.push({
								name: 'PaginaErro',
								params: {
									msg: responseToken.data,
								},
							})
						}
					}
				})
			}

			return {
				email,
				passwd,
				token,
				loginStore,
				router,
				efetuarLogin,
				gerarNovaSenha,
				verified,
				expired,
				tokenCaptcha,
				eKey,
				error,
				onVerify,
				onExpire,
				onChallengeExpire,
				onError,
			}
		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	h3 {
		margin: 40px 0 0;
		color: #000000;
	}

	ul {
		list-style-type: none;
		padding: 0;
	}

	li {
		display: inline-block;
		margin: 0 10px;
	}

	a {
		color: #42b983;
	}

	/*Bootstrap: Mudança da cor dos botões*/
	.btn-info {
		background-color: #3276b1;
		border-color: #3276b1;
		color: #ffffff;
	}

	/*Bootstrap: Mudança da cor dos botões*/
	.btn-success {
		background-color: #58b358;
		border-color: #58b358;
	}

	/*Evita a geração de scroll horizontal*/
	.row {
		margin-right: 0px;
		margin-left: 0px;
		margin-top: 15px;
	}

	.row-margin-top {
		margin-top: 15px;
	}
</style>

<style src="@vueform/multiselect/themes/default.css"></style>
